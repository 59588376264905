// Theme customization
//@import "theme/typography";
//@include mat.all-component-themes($app-theme);

//.first-typography {
//  @include angular-material-typography($custom-typography);
//}

.new-product-lcc {
  font-weight: bold;
  top: 18%;
  right: 18%;
  left: 14%;
  text-align: center;
  position: inherit;
  transform: rotate(-13grad);
  letter-spacing: 0;
  font-size: 26px;
  word-break: break-word;
  white-space: nowrap;
}

.section-blue-color {
  //.first-typography {
  @include section-padding;
  background-color: $kw-colors-blue;
}

.section-red-color {
  //.first-typography {
  @include section-padding;
  background-color: $kw-colors-red;
}

.section-white-color {
  //.first-typography {
  @include section-padding;
  background-color: $kw-colors-white;
}

.section-grey-color {
  //.first-typography {
  @include section-padding;
  background-color: $kw-color-grey;
}

p.mt-3 {
  margin-top: 20px;
}

.mt-4 {
  margin-top: 1.5rem;
}

.kennwertered {
  color: $kw-colors-red;
}

.brightfontwd {
  color: #fff;
}

.info-section.mat-mdc-card {
  padding: 0;
}

.mat-mdc-card {
  font-family: "Roboto Condensed", "Helvetica Neue", "Arial", "sans-serif";
}

::ng-deep .mat-mdc-card.p-0 {
  padding: 0;
}

#bulletPointsElement label.clearLabel,
.cDescription {
  font-size: 14px;
}

button,
html,
input,
select,
textarea {
  font-family: "Roboto Condensed", "Helvetica Neue", "Arial", "sans-serif";
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  color: inherit;
  //color: $kw-color-black;
}

h1,
.h1,
h2,
.h2,
h3,
.h3 {
  margin-top: 20px;
  margin-bottom: 10px;
}

h3 {
  font-size: 24px;
}

h4,
h5,
h6 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.h4,
h4 {
  font-size: 18px;
}

a {
  color: #337ab7;
  text-decoration: none;
}

a:focus,
a:hover {
  color: #23527c;
  text-decoration: underline;
  cursor: pointer;
}

.mt-5 {
  margin-top: 5px;
}

.mb-3 {
  margin-bottom: 1rem;
}

.fwb {
  font-weight: bold;
}

.clearFloat {
  clear: both;
}

// header css changes
.logoObj4 {
  margin-left: 196px;
  margin-top: 30px;
  height: 19px;
}

.navbar-header a {
  text-decoration: none;
  height: 35px;
  display: block;
  position: absolute;
  top: 50%;
  margin-top: -40px;
}

.mat-mdc-menu-content button.mat-mdc-menu-item.active {
  background-color: rgba(0, 0, 0, 0.5);
}

.logoObj3 {
  margin-left: 194px;
  margin-top: 10px;
  height: 45px;
}

.cDescription > h4 {
  white-space: nowrap;
}

.cAdditionalInfo {
  /*overflow-y: hidden;*/
  height: 431px;
  transition: 0.6s all cubic-bezier(0.73, -0.485, 0.145, 1.62);
}

.cAdditionalInfoClosed {
  height: 0px;
}

.dimension-modalbox mat-dialog-container {
  padding: 0 0 30px 0;
  overflow-y: auto;
  height: auto;
}

.dimension-modalbox.video-container mat-dialog-container {
  padding: 0 0 30px 0;
  overflow-y: auto;
  height: auto;
}

.settings-modalbox mat-dialog-container {
  padding: 0 0 30px 0;
  overflow-y: scroll;
  height: 80vh;
}

.info-modalbox mat-dialog-container {
  padding: 0 0 30px 0;
  height: auto;
  width: 600px;
  margin-left: 25%;
  margin-right: 25%;
}

::ng-deep .cdk-overlay-container {
  //position: fixed;
  z-index: 1080;
}

::ng-deep .cdk-overlay-pane {
  z-index: 1020;
}

.mat-mdc-card.login-box {
  box-shadow: none;
}

div.cFormElement {
  float: left;
  height: 38px;
  font-size: 14px;
}

.rebuildElement {
  /*height: 40px;*/
  width: $kv-form-input-width;
  float: left;
}

/*.mat-primary.submit-btn {
  width: 100px;
  border-radius: 0;
  border: 1px solid;
  background-color: hsla(2, 32%, 61%, 0.05);
  border-color: hsla(2, 32%, 61%, 0.8);
  color: #bb7c7a;
  cursor: pointer;
}*/

//.mat-primary.submit-btn:hover {
//  color: #333;
//  border-color: hsla(2, 32%, 61%, 0.8);
//}

.mat-raised-button.mat-button-disabled {
  border-color: unset;
}

.mat-raised-button.mat-button-disabled:hover {
  border-color: unset;
  color: rgba(0, 0, 0, 0.26);
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-mdc-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #c88a88;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-mdc-form-field.mat-form-field-invalid .mat-form-field-ripple {
  background-color: #c88a88;
}

/*model cascade dropdwon:
this class is usded for realestate and realEstate ref object height */
/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.model-cascade-dropdown.mat-form-field-appearance-fill .mat-form-field-flex {
  border-radius: 0;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.realestate-parent .mat-form-field-appearance-fill .mat-form-field-flex {
  height: 2rem;
}

.kw-new-product.btn-xlg {
  padding: 9px 32px;
}

.kw-new-product {
  position: absolute;
  top: 21%;
  right: 2%;
  border-radius: 50% !important;
  height: 8em;
  width: 8em;
}

.kw-new-product-2 {
  right: 21%;
}

.kw-new-product-3 {
  top: 19.5%;
  right: 15%;
  height: 60%;
}

.kv-contact-btn {
  position: fixed;
  bottom: 5%;
  right: 60px;
  z-index: 0;
  > a {
    text-align: left;
  }
}

.kv-contact-demo-btn {
  margin-bottom: 5px;
  border: 1px solid #fff;
  border-radius: 4px;
  > a {
    text-align: left;
  }
}

.mt-4 {
  margin-top: 1.5rem;
}

html {
  scroll-behavior: smooth;
}

.fontbold {
  font-weight: 600 !important;
}

// sub navigain bar
.mat-mdc-card.kv-mat-card {
  padding: 0;
}

.contact-container {
  align-items: center;
  justify-content: center;
}

.nav {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-justified {
  width: 100%;
}

.nav {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-pills > li + li {
  margin-left: 2px;
}

.nav-pills > li > a {
  border-radius: 4px;
}

.nav > li > a {
  padding: 10px 5px;
  position: relative;
  display: block;
}

.nav > li > a:focus,
.nav > li > a:hover {
  text-decoration: none;
  cursor: pointer;
  background: #d7e2ea;
}

.nav-item {
  text-align: center;
}

.nav-item > a > img {
  width: 60px;
  height: 60px;
}

.nav li > a > figcaption {
  color: #747474;
  font-size: 12px;
}

.btn:hover {
  color: #333;
  text-decoration: none;
}

.kw-label {
  font-size: 14px;
  color: $kw-color-black;
  font-weight: normal;
}

.not-relevant .cFormElement .cLabel label,
.not-relevant .cLabelSlider label {
  color: #a5cef2;
}

/* TODO(mdc-migration): The following rule targets internal classes of tooltip that may no longer apply for the MDC version.*/
.mat-mdc-tooltip.kv-tooltip.mat-tooltip-show {
  position: relative;
  visibility: visible;
  background-color: #bb7c7a;

  &::after {
    width: 0;
    height: 0;
    content: "";
    position: absolute;
    border-left: 0.5rem solid transparent;
    border-right: 0.5rem solid transparent;
    border-bottom: 0.5rem solid #bb7c7a;
  }

  &.right {
    overflow: initial;
    margin-left: 1rem;

    &:after {
      left: -0.75rem;
      top: calc(50% - 0.25rem);
      transform: rotate(270deg);
    }
  }
}

// Slider CSS Starts

.subSlider {
  margin-left: 25px;
}

//.mat-slider-thumb {
//  width: 25px;
//  height: 25px;
//  position: relative;
//  cursor: default;
//  background-color: rgba(0, 0, 0, 0.26);
//  background-repeat: repeat-x;
//  background-position: 0 0px;
//  pointer-events: none;
//}

.sliderInterval2 {
  width: 22px;
  height: 22px;
  top: 17px;
  position: absolute;
  display: block;
  cursor: default;
  z-index: 0.5;
  background-image: url(/assets/images/non-rev/default_setting_interval2_grey.png);
  background-repeat: repeat-x;
  background-position: 0 0px;
  pointer-events: none;
}

.sliderInterval3 {
  width: 22px;
  height: 22px;
  top: 17px;
  position: absolute;
  display: block;
  cursor: default;
  z-index: 0.5;
  background-image: url(/assets/images/non-rev/default_setting_interval3_grey.png);
  background-repeat: repeat-x;
  background-position: 0 0px;
  pointer-events: none;
}

.sliderInterval4 {
  width: 22px;
  height: 22px;
  top: 17px;
  position: absolute;
  display: block;
  cursor: default;
  z-index: 0.5;
  background-image: url(/assets/images/non-rev/default_setting_interval4_grey.png);
  background-repeat: repeat-x;
  background-position: 0 0px;
  pointer-events: none;
}

.sliderInterval5 {
  width: 22px;
  height: 22px;
  top: 17px;
  position: absolute;
  display: block;
  cursor: default;
  z-index: 0.5;
  background-image: url(/assets/images/non-rev/default_setting_interval4_grey.png);
  background-repeat: repeat-x;
  background-position: 0 0px;
  pointer-events: none;
}

//.mat-slider.mat-slider-horizontal {
//  //height: 2px;
//  //width: 355px;
//}

//.mat-slider-focus-ring {
//  background-color: rgba(0, 0, 0, 0.26);
//}

//.mat-slider-horizontal .mat-slider-wrapper {
//  height: 2px;
//  top: 23px;
//  left: 8px;
//  right: 8px;
//  margin-top: 22px;
//}

.cInfoButton {
  display: none;
}

.irs-line {
  border-top-left-radius: 40%;
  border-bottom-left-radius: 40%;
}

.irs .irs-bar {
  background: #aeaeae;
}

.irs-line-left {
  background: #aeaeae;
  /*#5F5F5F;*/
}

.irs-line-mid {
  background: #aeaeae;
  /*#5F5F5F;*/
}

.irs-line-right {
  background: #aeaeae;
  /*#5F5F5F;*/
  border-top-right-radius: 30%;
  border-bottom-right-radius: 30%;
}

.irs-bar-edge {
  background: #aeaeae;
  border-top-left-radius: 40%;
  border-bottom-left-radius: 40%;
}

.fr .analysisYear {
  margin-left: 0;
}

.fr .analysisQuantity {
  width: 87px;
}

.analysisQuantity {
  width: 82px;
  display: flex;
  margin-right: 10px;
}

.fr .analysisCondition {
  padding-left: 5px;
}

.fr .analysisResidualLifetime {
  width: 147px;
}

.analysisResidualLifetime {
  width: 140px;
  margin-left: 15px;
  margin-right: 15px;
  float: left;
}

.fr .analysisInterventionGrade {
  width: 96px;
}

.analysisInterventionGrade {
  width: 110px;
  margin-left: 15px;
  float: left;
}

.list-unstyled {
  list-style: none;
  padding-left: 0px;
}

/******************
** Slider Header and Background
 ******************/

.WKHeader {
  overflow: visible;
}

.sliderValueBox {
  display: inline-grid;
  background-color: #e6e6e6;
  // height: 310px;
  // height: 245px;
  /*background-color: rgba(187, 124, 122, 0.16);*/
}

.sliderValueBoxLightGrey {
  display: inline-grid;
  background-color: rgb(243 243 243);
}

.sliderBox {
  position: absolute;
  padding: 0px 0px;
  //margin-left: 280px;
  margin-top: 0px;
}

.sliderValueBox > label,
.sliderValueBoxLightGrey > label {
  text-align: center;
  font-size: 12px;
}

.greyBoxComplexity {
  height: 190px;
}

.greyBoxRebuild {
  height: 373px;
  background-color: #e6e6e6;
}

.greyBoxComplexityInsur {
  height: 153px;
}

.greyBoxComplexityInsurGvbs {
  height: 194px;
}

.greyBoxQuality {
  height: 230px;
}

.greyBoxQuality-reference {
  height: 240px;
}

.greyBoxRebuildQuality {
  /*height: 140px;
  this is the real one
  */
  height: 187px;
}

.greyBoxQualityInsur {
  height: 191px;
}

.greyBoxQualityOpCost {
  height: 352px;
}

.greyBoxQualityOpCost2 {
  height: 185px;
}

.sliderValueBox > label,
.sliderValueBoxLightGrey > label {
  margin-bottom: 0px;
}

// new changes
.cFormSlider .ngx-slider .ngx-slider-bar {
  background: #aeaeae;
}

.cFormSlider .ngx-slider .ngx-slider-selection {
  z-index: 2;
  background: #aeaeae;
  border-radius: 2px;
}

.cFormSlider .ngx-slider-pointer {
  background-color: #fff !important;
}

.cFormSlider .ngx-slider-pointer::after {
  background-color: #a79d9d !important;
}

.info-box-z-index {
  z-index: 11000 !important;
}

/******************
** Slider Header and Background  END
******************/

/******************
** BulletPoint circle
******************/
.bulletPoint {
  list-style: circle;
}

/******************
** BulletPoint circle END
******************/

.zB {
  display: inline;
  float: left;
  font-weight: normal;
  margin-bottom: 0px;
  min-height: 170px;
}

.clearLabel {
  font-weight: normal;
  margin-bottom: 0px;
}

//Blotbox

/***********
***Input Slider rebuild - custom tick label (color / background)
***********/
.bwSlider .irs-single {
  color: black;
  background: none;
  opacity: 0;
}

.bwSlider .irs-single--active {
  opacity: 1;
  z-index: 10000;
}

.sliderCentered > span.irs {
  bottom: 12px;
}

/*
*** When Boxplot is present label is not showing
*/
.showSliderLabel .irs-single {
  opacity: 1;
  /*z-index: 10000;*/
}

// Slider Ends
.cFormElement {
  font-size: 14px;
  color: $kw-color-black;
}

// new buildingcost calc Start

// new building ends

/**
 * Customized Buttons
 */
.btn {
  border-radius: 1%;
  /* sharp buttons */
}

.btn.btn-xlg,
.btn-xlg {
  padding: 12px 18px;
  font-size: 24px;
  //line-height: 1.3333333;
}

.pom.btn-transparent-orange {
  font-weight: 500;
  border-radius: 0;
  border-top: 1px solid transparent;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  border-bottom: 1px solid rgba(19, 217, 107, 0.5);
  /*border-bottom: 1px solid rgba(64, 160, 141, 0.5);*/
  color: rgba(64, 160, 141, 1);
  background-color: transparent;
}

.btn.btn-xlg .btn-transparent-orange,
.btn-transparent-orange {
  font-weight: 500;
  border-radius: 0;
  border-top: 1px solid transparent;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  // border-bottom: 1px solid rgba(187, 124, 122, 0.5);
  color: rgba(187, 124, 122, 1);
  background-color: transparent;
}

//used on small building - when uploading fotos.
.picture-wrapper {
  margin-top: 15px;
  display: inline-block;
}

.pom.btn-transparent-orange:focus {
  border-top: 1px solid transparent;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  color: rgba(19, 217, 107, 1);
  /*color: rgba(64, 160, 141, 1);*/
  background-color: transparent;
  box-shadow: none;
  outline: none !important;
}

/* Genereted with https://blog.koalite.com/bbg/ */
.kw-attention,
.rowlightRed {
  background-color: #c88a88;
}

.btn-warning.active,
.btn-warning:active,
.btn-warning:hover,
.open > .dropdown-toggle.btn-warning {
  color: #fff;
  background-color: #ec971f;
  border-color: #d58512;
}

.btn.focus,
.btn:focus,
.btn:hover {
  color: #333;
  text-decoration: none;
}

.btn {
  border-radius: 1%;
}

.btn-warning {
  color: #fff;
  background-color: #f0ad4e;
  border-color: #eea236;
}

.btn-primary-kw {
  color: #fff;
  background-color: #bb7c7a;
  border-color: #bb7c7a;
}

.btn-primary-kw:hover,
.btn-primary-kw:focus,
.btn-primary-kw:active,
.btn-primary-kw.active,
.open .dropdown-toggle.btn-primary-kw {
  color: #fff;
  background-color: #884947;
  border-color: #bb7c7a;
}

.btn-primary-kw:active,
.btn-primary-kw.active,
.open .dropdown-toggle.btn-primary-kw {
  background-image: none;
}

.btn-primary-kw.disabled,
.btn-primary-kw[disabled],
fieldset[disabled] .btn-primary-kw,
.btn-primary-kw.disabled:hover,
.btn-primary-kw[disabled]:hover,
fieldset[disabled] .btn-primary-kw:hover,
.btn-primary-kw.disabled:focus,
.btn-primary-kw[disabled]:focus,
fieldset[disabled] .btn-primary-kw:focus,
.btn-primary-kw.disabled:active,
.btn-primary-kw[disabled]:active,
fieldset[disabled] .btn-primary-kw:active,
.btn-primary-kw.disabled.active,
.btn-primary-kw[disabled].active,
fieldset[disabled] .btn-primary-kw.active {
  background-color: #bb7c7a;
  border-color: #bb7c7a;
}

.btn-primary-kw .badge {
  color: #bb7c7a;
  background-color: #fff;
}

/* </editor-fold> */

.pom.btn-transparent-orange:active,
.pom.btn-transparent-orange.active,
.pom.btn-transparent-orange.active:focus {
  /*border-top: 1px solid rgba(187, 124, 122, 0.8);*/
  /*border-left: 1px solid rgba(187, 124, 122, 0.8);*/
  /*border-right: 1px solid rgba(187, 124, 122, 0.8);*/
  /*color: rgba(64, 160, 141, 1);*/
  color: rgba(19, 217, 107, 1);
  background-color: rgba(64, 160, 141, 0.1);
  box-shadow: none;
  outline: none !important;
}

.btn-elegant-green {
  font-weight: 500;
  border-radius: 0;
  border-top: 1px solid;
  border-left: 1px solid;
  border-right: 1px solid;
  border-bottom: 1px solid;
  /*color: rgba(55, 137, 130, 1.0);*/
  /*background-color: rgba(55, 137, 130, 0.05);*/
  /*border-color: rgba(55, 137, 130, 0.8);*/
  /*pom green*/
  color: rgba(19, 217, 107, 1);
  background-color: rgba(19, 217, 107, 0.05);
  border-color: rgba(19, 217, 107, 0.8);
}

.btn-elegant-green:hover,
.btn-elegant-green:active,
.btn-elegant-green.active,
.btn-elegant-green.active:focus {
  border-top: 1px solid;
  border-left: 1px solid;
  border-right: 1px solid;
  /*color: rgba(55, 137, 130, 1.0);*/
  /*background-color: rgba(55, 137, 130, 0.2);*/
  /*border-color: rgba(55, 137, 130, 0.8);*/

  color: rgba(19, 217, 107, 1);
  background-color: rgba(19, 217, 107, 0.2);
  border-color: rgba(19, 217, 107, 0.8);
  box-shadow: none;
  outline: none !important;
}

.btn-elegant-black-white {
  color: white;
  font-weight: 500;
  border-radius: 0;
  border-top: 1px solid transparent;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  background-color: rgba(43, 42, 42, 0.6);
  letter-spacing: normal;
}

.btn-elegant-black-white:hover,
.btn-elegant-black-white:active,
.btn-elegant-black-white.active,
.btn-elegant-black-white.active:focus {
  color: white;
  border-top: 1px solid;
  border-left: 1px solid;
  border-right: 1px solid;
  border-color: rgba(43, 42, 42, 0.6);
  outline: none !important;
}

.btn-elegant-black-white:focus {
  color: white;
  box-shadow: none;
  outline: none !important;
}

.btn-elegant-white-title {
  background-color: rgba(255, 255, 255, 0.25);
}

.btn-orange {
  font-weight: 500;
  border-radius: 4%;
  border-top: 1px solid rgba(60, 69, 68, 0.5);
  border-left: 1px solid rgba(60, 69, 68, 0.5);
  border-right: 1px solid rgba(60, 69, 68, 0.5);
  border-bottom: 1px solid rgba(60, 69, 68, 0.5);
  color: rgba(187, 124, 122, 1);
  background-color: rgba(60, 69, 68, 1);
}

.btn-orange:focus,
.btn-orange:hover,
.btn-orange:active,
.btn-orange.active,
.btn-orange.active:focus {
  font-weight: 500;
  border-top: 1px solid rgba(187, 124, 122, 0.8);
  border-left: 1px solid rgba(187, 124, 122, 0.8);
  border-right: 1px solid rgba(187, 124, 122, 0.8);
  border-bottom: 1px solid rgba(187, 124, 122, 0.8);
  color: rgba(187, 124, 122, 1);
  background-color: rgba(60, 69, 68, 0.8);
  box-shadow: none;
  outline: none !important;
}

.btn-danger {
  margin-bottom: 0;
  margin-left: 5px;
  color: #fff;
  background-color: #c9302c;
  border-color: #ac2925;
}

.btn-danger-light {
  color: rgba(178, 34, 34, 1);
  background-color: white;
  border-color: rgba(178, 34, 34, 0.5);
}

.btn-danger-light:hover {
  color: rgba(178, 34, 34, 0.8);
  background-color: white;
  border-color: rgba(178, 34, 34, 0.5);
}

/* Independent of the screen size */
#sectionId_howto,
#sectionId_referenceHowto {
  background-color: #e5f0f9;
  /* blue */
}

.pom-background {
  background-color: rgba(19, 217, 107, 1) !important;
}

#sectionId_general,
#sectionId_rebuild_overhaul_geometry,
#sectionId_state_analysis {
  background-color: #fdfdfe;
  /* white */
}

#sectionId_geometry,
#sectionId_legal,
#sectionId_rebuild_additions {
  background-color: #e7e7e7;
  /* gray */
}

#sectionId_complexity {
  background-color: #fdfdfe;
  /* white */
}

#sectionId_quality,
#sectionId_target,
#sectionId_rebuild_complexity,
#sectionId_rebuild_quality,
#sectionId_rebuild_overhaul_geometry_sliders,
#sectionId_pvalue {
  background-color: #e5f0f9;
  /* blue */
}

#sectionId-small-build-cost-class,
#sectionId_documents {
  background-color: #fdfdfe;
  /* white */
}

#sectionId-small-build-cost-class {
  min-height: 450px;
}

#sectionId_AdditionprocessOperation,
#sectionId_rebuild_completeInput {
  background-color: #fdfdfe;
  /* white */
}

#sectionId_RebuildAdditionprocessOperation {
  background-color: #e5f0f9;
  /* blue */
}

#sectionId_completeInput,
#sectionId_scheduling {
  background-color: #e7e7e7;
  /* gray */
}

#sectionId_calculating,
#sectionId_finishReference {
  background-color: #e7e7e7; /* gray */
}

/*styles for success forms start*/
.flexBoxOuter {
  display: flex;
  justify-content: center;
  /*flex-direction: row;*/
  align-items: center;
}

.flexBoxInner {
  width: 100%;
  margin-left: 10px;
  margin-right: 10px;
}

.alert-container {
  border: 1px solid #ebccd1;
  padding: 15px;
  margin-bottom: 10px;
  color: #b7605f;
  background-color: #f2dede;
}

.rowBackgroundBlue {
  background-color: #e5f0f9;
}

.rowBackgroundWhite {
  background-color: #fdfdfe;
}

.rowBackgroundGray {
  background-color: #e7e7e7;
}

/*styles for success forms end*/

/* favorite table style start */
.favoriteTable.table > thead > tr > th,
.favoriteTable.table > tbody > tr > th,
.favoriteTable.table > tfoot > tr > th,
.favoriteTable.table > thead > tr > td,
.favoriteTable.table > tbody > tr > td,
.favoriteTable.table > tfoot > tr > td {
  border: none;
  padding: 2px;
}

.favoriteTable.table > tbody > tr > td {
  border-bottom: 1px solid #e5f0f9;
}

.favoriteTable.table > thead > tr {
  border-bottom: 3px solid #e5f0f9;
}

.favoriteTable.table > tbody > tr:hover {
  background-color: #bb7c7a;
  color: white;
}

/* favourite table style end*/

/* building cost table start */
.rowTitle {
  margin-top: 7px;
  margin-bottom: 4px;
  color: $kw-color-black;
}

.respRowWidth {
  color: $kw-color-black;
}

.respRowWidth .bannerTitle {
  color: $kw-colors-white;
}

.mb-10 {
  margin-bottom: 10px;
}

.font-bold {
  font-weight: bold;
}

.centeredBox {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}

.center-wrapper-box {
  margin-top: 20px;
}

.centeredBoxText {
  text-align: center;
}

.table {
  background-color: transparent;
}

.table {
  border-spacing: 0;
  border-bottom: 1px solid #e5f0f9;
  border-collapse: collapse;
}

/* Use table-blue-border as a customization for bootstrap's table and table table-bordered */
.table-blue-border > thead > tr > th,
.table-blue-border > tbody > tr > th,
.table-blue-border > tfoot > tr > th {
  border-bottom: 3px solid #e5f0f9;
  padding: 0px 1px 2px 2px;
  border-right-width: 0px;
  border-left-width: 0px;
  line-height: 1.42857143;
}

.table-blue-border > colgroup:not(:first-child) {
  border-left: 1px solid #e5f0f9;
}

.table-blue-border > thead > tr > td,
.table-blue-border > tbody > tr > td,
.table-blue-border > tfoot > tr > td {
  border: 1px solid #e5f0f9;
  padding: 1px 1px 2px 2px;
  border-right-width: 0px;
  border-left-width: 0px;
  line-height: 1.42857143;
}

// /* Use table-gray-sm-border as a customization for bootstrap's table and table table-bordered */
// .table-gray-sm-border > thead > tr > th,
// .table-gray-sm-border > tbody > tr > th,
// .table-gray-sm-border > tfoot > tr > th {
//   border: 3px solid #ddd;
//   border-right-width: 0px;
//   border-left-width: 0px;
// }

// .table-gray-sm-border > thead > tr > td,
// .table-gray-sm-border > tbody > tr > td,
// .table-gray-sm-border > tfoot > tr > td {
//   border: 1px solid #ddd;
//   padding: 6px;
//   border-right-width: 0px;
//   border-left-width: 0px;
// }

/* Use table-striped-kw as a customization for bootstrap's table and table table-striped */
.table-striped-kw > tbody > tr:nth-child(odd) > td,
.table-striped-kw > tbody > tr:nth-child(odd) > th {
  background-color: #e5f0f9;
}

.table-striped-kw-grey > thead > tr,
.table-striped-kw-grey > tbody > tr {
  border-bottom: 1px solid $kw-color-grey;
}

.table-striped-kw-grey > tbody > tr:nth-child(odd) > td,
.table-striped-kw-grey > tbody > tr:nth-child(odd) > th {
  background-color: rgba(248, 248, 248, 0.99);
}

/* rows (tr, or th) can be marked with background-kennwerte */
.background-kennwerte > td,
.background-kennwerte > th,
.background-kennwerte {
  background-color: rgba(187, 124, 122, 1) !important;
}

.table-no-border thead > tr > th,
.table-no-border tbody > tr > th,
.table-no-border tfoot > tr > th,
.table-no-border thead > tr > td,
.table-no-border tbody > tr > td,
.table-no-border tfoot > tr > td {
  border: none;
  padding: 8px;
  line-height: 1.42857143;
  vertical-align: top;
  font-size: 14px;
}

.kw-table-col-1 {
  width: 330px;
}

/* building cost table end */

/* dynamic image css starts */

.header {
  position: relative;
  height: 508px;
}

.header .content {
  height: 100%;
}

.contentRowInner {
  padding-left: 10px !important;
}

.bannerFlexBox {
  height: 100%;
  align-items: flex-end !important;
}

.bannerImg h1 {
  text-align: left;
  //letter-spacing: 2px;
  font-size: 70px;
  white-space: nowrap;
  margin-top: 0px;
  margin-bottom: 0px;
}

.bannerImg h3 {
  font-size: 31px;
}

.bannerImg h4 {
  text-align: left;
  /*letter-spacing: 2px;*/
  /*font-size: 58px;*/
  white-space: nowrap;
  margin-top: 0px;
  margin-bottom: 0px;
}

.bannerModeRealestatesOverview {
  position: relative;
  right: 44%;
}

.header h3 {
  text-align: left;
  white-space: nowrap;
  margin-top: 0px;
  margin-bottom: 0px;
}

// .header > .content {
//   /*height: 300px;*/
// }

.infoMessage {
  text-align: center;
  font-size: large;
  background-color: #bb7c7a;
  color: #fff;
  overflow: hidden;
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 26%);
  border-radius: 5px;
  padding: 15px;
}

.additionalInfoBox {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
}

.additionalInfoBoxBottom {
  position: absolute;
  width: 100%;
  bottom: 0;
}

.additionalInfoBoxCenter {
  position: absolute;
  width: 100%;
  bottom: 50%;
  right: 50%;
}

.additionalInfoBoxTop {
  position: absolute;
  width: 100%;
  top: 0;
}

.drawer-open .contentRowInner {
  justify-content: left;
}

.contentRowInner {
  /*border: 1px solid yellow;*/
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex-direction: row;
  padding-top: 15px;
  padding-left: 0px;
  padding-bottom: 15px;
}

.contentRowInner2 {
  display: flex;
  align-items: stretch;
  flex-direction: row;
  padding-top: 15px;
  justify-content: center;
  padding-bottom: 15px;
}

.additionalInfoBox {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
}

.additionalInfoBoxBottom {
  position: absolute;
  width: 100%;
  bottom: 0;
}

.additionalInfoBoxCenter {
  position: absolute;
  width: 100%;
  bottom: 50%;
  right: 50%;
}

.additionalInfoBoxTop {
  position: absolute;
  width: 100%;
  top: 0;
}

.info-box-z-index {
  z-index: 11000 !important;
}

.contentRowInner .ctPartForm,
.contentRowInner2 .ctPartForm {
  /*border: 1px solid blue;*/
}

.contentRowInner .ctPartImg,
.contentRowInner2 .ctPartForm {
  /*border: 1px solid red;*/
}

.contentRowInner .ctPartImg .cImage img,
.contentRowInner2 .ctPartImg .cImage img,
.contentRowInner .ctPartImg .cImage object,
.contentRowInner2 .ctPartImg .cImage object {
  object-fit: cover;
  border-radius: 50%;
}

.st3 {
  fill: #13d96b;
}

.contentRowInner .ctPartDescr,
.contentRowInner2 .ctPartDescr {
  /*border: 1px solid green;*/
  margin-top: 0px;
  margin-left: 20px;
  margin-right: 5px;
}

.bannerImg {
  text-align: end;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center bottom;
  color: #fff;
  height: 100%;
}

/* dynamic-image css ends*/

/*modal css*/

.modal-header {
  padding: 8px;
  border-bottom: 1px solid #e5e5e5;
}

.modal-body {
  position: relative;
  padding: 15px;
}

.modal-footer {
  padding: 15px;
  float: right;
  border-top: 1px solid #e5e5e5;
}

.cFormElement .cLabelAdvanced,
.cFormElementSlim .cLabelAdvanced {
  width: 40px;
  margin-left: 20px;
  float: left;
  height: 100%;
}

.cFormElementSlim {
  float: left;
  height: 20px;
  width: 200px;
}

.cLabel2 label,
.cLabelAdvanced label,
.cLabel label {
  font-weight: 400;
  padding-top: 5px;
}

.cLabelAdvanced label {
  font-size: 14px;
}

//tooltip
#tippy-1 {
  max-width: calc(100vw - 10px);
  z-index: 10480000000000 !important;
  visibility: visible;
  position: absolute;
  inset: 0px auto auto 0px;
  margin: 0px;
  transform: translate(754.5px, 1057.5px);
}

.tippy-box[data-theme~="light"] {
  // color: #26323d;
  box-shadow:
    0 0 20px 4px rgb(154 161 177 / 15%),
    0 4px 80px -8px rgb(36 40 47 / 25%),
    0 4px 4px -2px rgb(91 94 105 / 15%);
  background-color: #bb7c7a;
}

.tippy-box {
  max-width: 350px;
  transition-duration: 0ms;
  // color: #26323d;
  box-shadow:
    0 0 20px 4px rgb(154 161 177 / 15%),
    0 4px 80px -8px rgb(36 40 47 / 25%),
    0 4px 4px -2px rgb(91 94 105 / 15%);
  background-color: #fff;
  position: relative;
  // background-color: #333;
  // color: #fff;
  border-radius: 4px;
  margin-left: 7px;
  font-size: 14px;
  line-height: 1.4;
  white-space: normal;
  outline: 0;
  transition-property: transform, visibility, opacity;
}

.tippy-content {
  transition-duration: 0ms;
  position: relative;
  padding: 5px 9px;
  z-index: 1;
  //margin-left: 4px;
  background-color: #bb7c7a;
  color: white;
  border-radius: 3px;
  // border: 1px solid white;
  border-top: 1px solid white;
  border-right: 1px solid white;
  border-bottom: 1px solid white;
  border-left: 1px solid white;
}

.tippy-box[data-theme~="light"][data-placement^="right"] > .tippy-arrow:before {
  border-right-color: #bb7c7a;
}

.tippy-arrow {
  width: 16px;
  height: 16px;
  color: #333;
  position: absolute;
  top: 0px;
  transform: translate(0px, 16.5px);
}

.tippy-box[data-placement^="right"] > .tippy-arrow {
  left: 0;
}

/* is verifed css for the image */
.greyScale {
  /*filter: url(filters.svg#grayscale); !* Firefox 3.5+ *!*/
  filter: grey; /* IE6-9 */
  -webkit-filter: grayscale(100%) brightness(100%); /* Google Chrome, Safari 6+ & Opera 15+ */
  filter: grayscale(100%);
}

.notVerified {
  opacity: 0.5;
}

/*disabled all form fields*/
.form-disabled {
  pointer-events: none;
  opacity: 0.5;
  background: #ccc;
}

/*Breadcrumbs css*/

.selectize-dropdown .active {
  background: rgba(187, 124, 122, 0.5);
  color: #fff;
}

.selectize-dropdown-content {
  overflow-x: auto;
  max-height: 290px;
  z-index: 450;
}

.selectize-dropdown.group-tree .goto-child-group {
  background: none;
  border: none;
  box-shadow: none;
  color: rgba(0, 0, 0, 0.5);
  height: 100%;
  line-height: 1;
  margin: 0;
  padding: 8px;
  position: absolute;
  right: 0;
  text-shadow: none;
  top: 0;
  -webkit-transition: background 0.2s;
  transition: background 0.2s;
}

.selectize-dropdown.group-tree .goto-child-group:hover {
  box-shadow: none;
  background: rgba(0, 0, 0, 0.1);
  border: none;
  color: #fff;
}

.selectize-dropdown.group-tree .ui-select-breadcrumbs {
  padding: 6px 12px;
  margin: 0;
  background: rgba(0, 0, 0, 0.8);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.selectize-dropdown.group-tree .ui-breadcrumb,
.ui-breadcrumb-back {
  color: white;
  cursor: pointer;
  font-size: 14px;
}

.selectize-dropdown.group-tree .ui-breadcrumb:hover,
.ui-breadcrumb-back:hover {
  color: #ff9000;
}

.selectize-dropdown.group-tree .ui-breadcrumb:after {
  content: ">";
}

.selectize-dropdown.group-tree .ui-breadcrumb-back:after {
  content: "";
}

.selectize-dropdown.group-tree .ui-breadcrumb:last-child {
  color: white;
  cursor: default;
}

.selectize-dropdown.group-tree .ui-breadcrumb:last-child:after {
  content: " ";
}

/*reference icons*/
.kv-reference-card {
  text-align: center;
  padding-left: 0.5%;
  padding-right: 0.5%;
  max-height: 100px;
  line-height: 100px;
  display: inline-block;
}

.kv-reference-card {
  width: 19%;
}

.kv-reference-row img {
  width: 60%;
  vertical-align: middle !important;
}

.kv-reference-row img.cm,
img.flubacher_nyfler_partner {
  width: 90%;
}

.kv-reference-row img.fws {
  width: 50%;
  padding-bottom: 30px;
}

.kv-reference-card .flubacher_nyfler_partner {
  width: 90%;
}

.kv-reference-card1 {
  height: 85px;
  width: 19%;
  text-align: center;
  /* max-height: 130px; */
  line-height: 100px;
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 2rem;
}

.kv-reference-card1 img {
  display: block;
  max-height: 140px;
  // height: 100%;
  width: 100%;
  object-fit: contain;
  text-align: center;
  padding: 25px;
}

.cImage img,
.cImage object {
  border-radius: 50%;
}

.white-hover-bg:hover {
  fill-opacity: 0.5;
}

.kennwerteorange {
  color: #ff8c00;
}

/*buttons*/

.biggerFont .btn {
  // font-size: 24px; //previous size
  font-size: 16px; // changed bu aditya
  border-radius: 1%;
}

.btn.focus,
.btn:focus,
.btn:hover {
  color: #333;
  text-decoration: none;
}

.btn-elegant-black {
  font-weight: 500;
  border-radius: 0;
  border: 1px solid;
  color: #000;
  background-color: rgba(0, 0, 0, 0.05);
  border-color: rgba(0, 0, 0, 0.8);
}

.btn {
  border-radius: 1%;
}

.kw-btn-grid {
  margin-top: 10px;
  margin-right: 10px;
}

.btn-primary {
  color: #fff;
  background-color: #337ab7;
  border-color: #2e6da4;
}

button[disabled],
html input[type="button"][disabled],
input[type="reset"][disabled],
input[type="submit"][disabled] {
  cursor: default;
}

button[disabled],
html input[disabled] {
  cursor: default;
}

.btn.disabled,
.btn[disabled],
fieldset[disabled] .btn {
  cursor: not-allowed;
  filter: alpha(opacity=65);
  box-shadow: none;
  opacity: 0.65;
}

.btn-success {
  color: #fff;
  background-color: #5cb85c;
  border-color: #4cae4c;
}

.btn-danger {
  color: #fff;
  background-color: #d9534f;
  border-color: #d43f3a;
}

.btn-primary {
  color: #fff;
  background-color: #337ab7;
  border-color: #2e6da4;
}

.btn {
  border-radius: 1%;
}

.btn {
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
}

.biggerFont .btn {
  font-size: 16px;
  border-radius: 1%;
}

.btn-elegant-black {
  font-weight: 500;
  border-radius: 0;
  border: 1px solid;
  color: #000;
  background-color: rgba(0, 0, 0, 0.05);
  border-color: rgba(0, 0, 0, 0.8);
}

/* button end*/
/*icon start*/

.glyphicon {
  position: relative;
  top: 1px;
  display: inline-block;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
}

/*icon end*/

.kw-btn-grid {
  margin-top: 10px;
  margin-right: 10px;
}

.mat-mdc-button,
.mat-icon-button,
.mat-stroked-button,
.mat-flat-button {
  font-weight: 500;
  border-radius: 0;
  border: 1px solid;
  color: #000;
}

.biggerFont h4 {
  font-size: 20px;
}

.brightfont {
  color: #747474;
  line-height: 1.5;
  &__larger {
    color: #747474;
    font-size: 16px;
    line-height: 1.5;
  }
}

.kw-team-card {
  float: left;
  min-height: 180px;
  max-height: 200px;
  //width: 20%;
  aspect-ratio: 1;
  margin-bottom: 16px;
}

.kw-team-img-container {
  display: inline-block;
}

.cImage img,
.cImage object {
  border-radius: 50%;
}

.advisoryBoardImage {
  height: 110px;
  width: 110px;
  float: left;
  margin-right: 5px;
  vertical-align: center;
  object-fit: cover;
}

.kw-team-text > label {
  max-width: none;
  margin-bottom: 0;
  //white-space: ;
}

.kv-team-img {
  height: 110px;
  width: 110px;
  float: left;
  //margin-right: 5px;
  vertical-align: center;
  object-fit: cover;
}

.kw-team-text > span {
  display: block;
}

.kw-team-row::after,
.kw-reference-row::after {
  content: "";
  clear: both;
  display: table;
  color: #000000;
}

label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: 700;
}

.swissbau {
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.swissbau > span {
  color: #fff;
}

circle {
  fill: white;
  fill-opacity: 0.1;
  stroke: white;
  stroke-width: 2px;
}

.compact-slider circle {
  fill: #b3b1b1;
  fill-opacity: 1;
  stroke: transparent;
  /* stroke-width: 1px; */
}

svg:not(:root) {
  overflow: hidden;
}

.co2-1 {
  pointer-events: none;
  font-size: 45px;
  white-space: nowrap;
  font-weight: normal;
  transform: translate(-50%, -50%);
  position: absolute;
  top: -135%;
}

.social-1-0 {
  font-size: 45px;
  white-space: nowrap;
  font-weight: normal;
  transform: translate(-50%, -50%);
  position: absolute;
  top: 38%;
  color: white;
  cursor: pointer;
}

.social-1-1 {
  /*pointer-events: none;*/
  font-size: 45px;
  white-space: nowrap;
  font-weight: normal;
  transform: translate(-50%, -50%);
  position: absolute;
  top: 38%;
  color: white;
  text-decoration-line: underline;
  text-decoration-thickness: 3px;
  cursor: pointer;
}

.social-1-1:hover {
  color: #747474;
}

.swissbau1 {
  top: -70%;
}

.swissbau1-1 {
  top: 15%;
}

.swissbau1,
.swissbau1-1 {
  pointer-events: none;
  font-size: 45px;
  white-space: nowrap;
  font-weight: 400;
  transform: translate(-50%, -50%);
  position: absolute;
}

.swissbau1,
.swissbau1-1 {
  pointer-events: none;
  font-size: 45px;
  white-space: nowrap;
  font-weight: 400;
  transform: translate(-50%, -50%);
  position: absolute;
}

.co2-3,
.swissbau2,
.swissbau3 {
  pointer-events: none;
  white-space: nowrap;
  font-size: 24px;
  font-weight: 400;
  transform: translate(-50%, -50%);
  position: absolute;
}

.swissbau2 {
  bottom: -120%;
}

.co2-3 {
  bottom: -150%;
}

.swissbau3 {
  bottom: -170%;
}

.swissbau2,
.swissbau3 {
  pointer-events: none;
  white-space: nowrap;
  font-size: 24px;
  font-weight: 400;
  transform: translate(-50%, -50%);
  position: absolute;
}

.kw-reference-row {
  // display: -ms-flexbox;
  display: flex;
  // -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  height: 100px;
}

.kw-reference-card,
.kw-reference-card-4 {
  text-align: center;
  padding-left: 0.5%;
  padding-right: 0.5%;
  max-height: 100px;
  line-height: 100px;
}

.kw-reference-card {
  width: 19%;
}

a {
  color: #337ab7;
  text-decoration: none;
  background-color: transparent;
}

.kw-reference-row img {
  vertical-align: middle !important;
}

.flexBoxInner.marginRight,
.pageId_Home .flexBoxInner.marginRight,
.pageId_LandingAgv .flexBoxInner.marginRight,
.pageId_ProcessForm .flexBoxInner.marginRight,
.pageId_Realestates .flexBoxInner.marginRight,
.pageId_Register .flexBoxInner.marginRight {
  margin-right: 60px;
}

.alert-kennwerte-info {
  color: #747474;
  background-color: #d1d9e5;
  border-color: #d1d9e5;
}

.alert {
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 4px;
}

.alert > p,
.alert > ul {
  margin-bottom: 0;
}

.newsBox ul {
  padding-left: 0;
}

.newsBox li {
  list-style-type: disc;
  list-style-position: inside;
  vertical-align: text-top;
}

/* ===========================================================
STYLES Start
============================================================ */

/**
this file (style.scss) will be compiled to style.css and copied into assets/css/ folder - never commit this file...
*/
.drop2downUsageTypesOuter,
.drop2down {
  width: 100%;
  float: left;
  height: 26px;
}

md-input-container.md-default-theme.md-input-focused .md-input:-moz-placeholder,
md-input-container.md-input-focused .md-input:-moz-placeholder,
md-input-container.md-default-theme.md-input-focused .md-input::placeholder,
md-input-container.md-input-focused .md-input::placeholder {
  color: rgba(0, 0, 0, 0.38);
}

.selectize-control.single .selectize-input::after {
  right: 5px;
}

.btn-sm {
  padding: 0px 3px;
}

.smallBuildingImg .calcRowImageGallery {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  width: 300px;
  height: 300px;
}

.fr .analysisYear {
  margin-left: 0;
}

.fr .analysisQuantity {
  width: 87px;
}

.analysisQuantity {
  width: 82px;
  display: flex;
  margin-right: 10px;
}

.fr .analysisCondition {
  padding-left: 5px;
}

.fr .analysisResidualLifetime {
  width: 147px;
}

.analysisResidualLifetime {
  width: 140px;
  margin-left: 15px;
  margin-right: 15px;
  float: left;
}

.fr .analysisInterventionGrade {
  width: 96px;
}

.analysisInterventionGrade {
  width: 100px;
  //margin-left: 15px;
  float: left;
}

.favorite .selectize-control.single .selectize-input:after {
  display: none;
}

.favorite .selectize-input {
  height: 25px;
  display: flex;
  background-color: rgba(151, 151, 151, 0);
  border-radius: 0px;
  border: transparent;
  border-bottom-color: rgba(0, 0, 0, 0) !important;
  border-bottom-width: 0px;
  border-bottom-style: hidden;
}

.favorite .selectize-input .ui-select-match.ng-scope {
  width: 0;
}

.favorite div span.ng-hide {
  display: inherit !important;
}

.favoriteTable.table > thead > tr > th,
.favoriteTable.table > tbody > tr > th,
.favoriteTable.table > tfoot > tr > th,
.favoriteTable.table > thead > tr > td,
.favoriteTable.table > tbody > tr > td,
.favoriteTable.table > tfoot > tr > td {
  border: none;
  padding: 2px;
}

.favoriteTable.table > tbody > tr > td {
  border-bottom: 1px solid #e5f0f9;
}

.favoriteTable.table > thead > tr {
  border-bottom: 3px solid #e5f0f9;
}

.favoriteTable.table > tbody > tr:hover {
  background-color: #bb7c7a;
  color: white;
}

.align-icon {
  vertical-align: middle;
}

.additionalInfoBox,
.contentRowInner,
.contentRowInner2 {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  //justify-content: left;
}

.contentRowInner,
.contentRowInner2 {
  -ms-flex-align: stretch;
  align-items: stretch;
  -ms-flex-direction: row;
  flex-direction: row;
  padding-top: 15px;
  padding-bottom: 15px;
}

/*Alerts (used in Admin Section) STARTS */
.alert-warning {
  color: #8a6d3b;
  background-color: #fcf8e3;
  border-color: #faebcc;
}

.alert {
  padding: 15px;
  margin-bottom: 20px;
  margin-top: 20px;
  border: 1px solid transparent;
  border-radius: 4px;
}

/*Alerts (used in Admin Section) END */

/* Overriding the existing css of mat-expansion STARTS*/
mat-expansion-panel-header {
  color: #333;
  background-color: #f5f5f5;
  border-color: #ddd;
}

// ::ng-deep .mat-expansion-panel-header.mat-expanded{ // used !important due to ..
//   color: #333 !important;
//   background-color: #f5f5f5 !important;
//   border-color: #ddd !important;
// }

/* Overriding the existing css of mat-expansion ENDS*/

.align-icon {
  vertical-align: middle;
}

app-slider-input {
  float: left;
}

// .sliderBox {
//   position: absolute;
//   padding: 0px 0px;
//   margin-top: -30px;
//   left: unset;
//   width: 380px;
//   margin-left: -5px;
// }

//.sliderBox {
//  position: absolute;
//  padding: 0px 0px;
//  margin-top: -30px;
//  width: 380px;
//  margin-left: 0px;
//}

.centerImage img {
  border-radius: 50%;
}

.userLogo {
  height: 35px;
  padding-bottom: 5px;
  border-radius: 0%;
  vertical-align: center;
}

/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
label.mat-checkbox-layout {
  font-weight: 400;
}
.mat-mdc-checkbox label {
  cursor: pointer;
  margin-bottom: 0;
}

.rebuildMarginRight {
  margin-right: 10px;
}

.bold {
  font-weight: 700;
}

.ref-obj .mat-mdc-dialog-container {
  padding: 0;
}

.ref-obj .cLabel {
  width: 265px;
}

.ref-obj .contentRowInner {
  padding-left: 0px !important;
}

.ref-obj .content {
  margin: 0 auto;
  overflow: visible;
}

/* ===========================================================
STYLES End
============================================================ */

/* =============================================================
Other Responsive CSS START
TBD:: Put a lable
=================================================================*/

/* new slider reponsive css start */
@media (min-width: 1025px) and (max-width: 1280px) {
  .sliderBox {
    position: absolute;
    padding: 0px 0px;
    left: unset;
    // right: 0;
    width: $kv-form-input-width;
    margin-left: 0px;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .sliderBox {
    position: absolute;
    padding: 0px 0px;
    margin-top: -30px;
    left: 370px;
    width: $kv-form-input-width;
    margin-left: 0px;
  }
}

/*new slider responsive css add */

@media (min-width: 1024px) and (max-width: 1499px) {
  .flexBoxInnerWider {
    width: 860px;
  }
}

@media (min-width: 1024px) and (max-width: 1499px) {
  .flexBoxInner.img {
    width: 300px;
  }
}

@media (min-width: 1024px) and (max-width: 1499px) {
  .flexBoxInner.marginRight {
    margin-right: 60px;
  }
}

@media (min-width: 1024px) and (max-width: 1499px) {
  .flexBoxInner {
    width: 700px;
  }
}

@media (min-width: 769px) {
  .nav-item {
    display: table-cell;
    width: 1%;
  }
}

@media (min-width: 0px) and (max-width: 599px) {
  .flexBoxInner.img {
    display: none;
  }

  .flexBoxInner.marginRight {
    margin-right: 0;
  }

  .flexBoxInner.txt {
    width: 100%;
  }

  .flexBoxInner {
    width: 100%;
    margin-left: 10px;
    margin-right: 10px;
  }

  .pageId_Home .list-inner-box {
    width: 90%;
    display: inline-flex;
  }

  .kw-new-product-3,
  .kw-new-product {
    display: none;

    > span {
      display: none;
    }
  }
}

@media (min-width: 600px) and (max-width: 1023px) {
  .flexBoxInnerWider {
    width: 500px;
  }
}

@media (min-width: 1550px) {
  .flexBoxInner.img {
    width: 402px;
  }

  .bannerModeRealestatesOverview {
    position: relative;
    right: 63%;
  }
}

@media (min-width: 600px) and (max-width: 1023px) {
  .pageId_Home .flexBoxOuter {
    // display: -ms-flexbox;
    display: flex;
    // -ms-flex-pack: center;
    justify-content: center;
    // -ms-flex-direction: column;
    flex-direction: column;
  }
}

@media (min-width: 600px) and (max-width: 1023px) {
  .flexBoxInnerWider {
    width: 500px;
  }
}

.mat-sidenav-content.mat-drawer-content {
  overflow: unset;
}

@media (min-width: 600px) and (max-width: 1023px) {
  .flexBoxOuter {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .flexBoxInner {
    width: 560px;
  }
  .bannerTextBox {
    margin-bottom: 32px;
  }

  .bannerImg h1 {
    font-size: 34px;
  }

  .flexBoxInner.marginRight {
    margin-right: 0;
  }

  .flexBoxInner.txt {
    width: 500px;
    -ms-flex-order: 1;
    order: 1;
  }

  .flexBoxInner.img {
    width: 300px;
    margin-top: 15px;
    order: 2;
  }

  .pageId_Home .circle-text-1,
  .circle-text2 {
    font-size: 35px !important;
  }

  .pageId_Home .flexBoxInner.contact-header {
    width: 560px;
    align-self: center;
    margin-right: 0;
    position: absolute;
    top: 170px;
    left: 70px;
  }

  .pageId_Home .contact-header .banner-title {
    font-size: 34px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .hidden-sm {
    display: none !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .hidden-md {
    display: none !important;
  }
}

@media (min-width: 1000px) and (max-width: 1754px) {
  .kw-new-product {
    position: absolute;
    top: 21%;
    left: unset;
    right: 0%;
    border-radius: 50% !important;
    height: 6em;
    width: 6em;

    &.kw-new-product-2 {
      display: none;
    }

    &.kw-new-product-3 {
      top: 21%;
      right: 12.5%;
      height: 45%;
    }
  }
}

@media (min-width: 600px) and (max-width: 767px) {
  .kw-new-product {
    display: none;
  }

  .kw-new-product-3,
  .kw-new-product {
    display: none;

    > span {
      display: none;
    }
  }
}

@media (max-width: 879px) {
  .kw-new-product-3,
  .kw-new-product {
    display: none;

    > span {
      display: none;
    }
  }
}

@media (min-width: 880px) and (max-width: 999px) {
  .kw-new-product {
    position: absolute;
    top: 21%;
    right: unset;
    left: 2%;
    border-radius: 50% !important;
    height: 6em;
    width: 6em;

    &.kw-new-product-2 {
      display: none;
    }

    &.kw-new-product-3 {
      height: 45%;
      right: 0%;
      left: unset;
    }

    > span {
      font-weight: bold;
      font-stretch: condensed;
      top: 6%;
      right: 23%;
      position: inherit;
      transform: rotate(-13grad);
      letter-spacing: 0px;
      font-size: 24px;
    }
  }
}

.cFormElement .cLabel,
.cFormElement .cLabelSlider {
  height: fit-content;
  width: 350px;
  display: inline-block;
  float: left;
}

.cInputElement {
  width: 440px;
  display: inline-block;
}

.label-and-input-row {
  width: $kv-form-label-width + 440px; //440px from above cInputElement.
}

@media (min-width: 1024px) and (max-width: 1279px) {
  .respRowWidth {
    width: 1100px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .bannerImg h1 {
    font-size: 34px;
  }

  .cFormElement .cLabel,
  .cFormElement .cLabelSlider {
    height: fit-content;
    height: fit-content;
    width: 350px;
    display: inline-block;
    float: left;
  }

  .cInputElement {
    width: 455px;
    display: inline-block;
  }

  cFormElement .cInputElement,
  .rebuildElementNormal {
    width: $kv-form-input-width;
    display: inline-block;
  }

  .flexBoxInnerNarrow {
    width: 450px;
  }

  #sectionIdGeneral .contentRowInner .ctPartDescr {
    min-height: 120px;
  }

  .contentRowInner .ctPartForm {
    width: 810px;
  }

  #sectionId_quality {
    min-height: 385px;
  }
}

//@media (min-width: 1280px) and (max-width: 1499px) {
@media (min-width: 1280px) {
  .respRowWidth {
    min-width: 1280px;
    //max-width: 1549px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;

    margin-left: 1%;

    & > .additionalInfoBox {
      flex-direction: row;
      -ms-flex-direction: row;
    }

    & > .analysisElement .additionalInfoBox {
      padding-left: 23px;
    }
  }

  .bannerImg h1 {
    font-size: 48px;
  }

  .contentRowInner .ctPartForm {
    width: 710px;
    position: relative;
    //left: 10px; //allignment with header this might be also an issue and needed somewhere else.
    left: 0;
  }

  .contentRowInner .ctPartImg {
    width: calc($kv-form-image-width * 2 / 3);
    height: 270px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .cFormElement div.cInputElement,
  .rebuildElementNormal {
    width: $kv-form-input-width;
    display: inline-block;
  }

  .cFormElement div.standard-box {
    width: 29%;
    display: inline-block;
  }

  .cFormElement .standated-percentage {
    float: right;
    display: inline-block;
    position: relative;
    width: 8%;
  }

  .cFormElement div.std-main-box {
    width: 60%;
    display: inline-block;
  }

  .contentRowInner .ctPartDescr {
    width: calc($kv-form-description-width * 140 / 181); //280px
    min-height: 270px;
    margin-top: 0;
  }

  .contentRowInner .ctPartImg .cImage .img-box {
    width: 260px;
    height: 260px;
  }

  .cImage img,
  .cImage .img-box {
    border-radius: 50%;
  }

  .contentRowInner .ctPartDescr {
    width: calc($kv-form-description-width * 140 / 181); //280px
    min-height: 270px;
    margin-top: 0;
  }

  .cFormElement .cLabel,
  .cFormElementSlim .cLabel {
    height: fit-content;
    height: fit-content;
    width: 350px; // changed for slider 310 to 290
    display: inline-block;
    float: left;
  }

  .cFormElement .cLabelSlider {
    height: fit-content;
    width: 350px; /*previou is 345px */
    display: inline-block;
    float: left;
  }

  .cInputElement,
  .rebuildElementNormal {
    width: $kv-form-input-width;
    display: inline-block;
  }

  .ref-obj .respRowWidth {
    display: flex;
    flex-direction: row;
    margin-left: 0;
    min-width: unset;
  }

  .ref-obj .contentRowInner .ctPartForm {
    width: 710px;
    position: relative;
    left: 8px;
  }
}

/* ===============================================================
Other Responsive CSS END
TBD:: Put a lable
==================================================================*/

/* ==========================================================================
Min Width
========================================================================== */

@media screen and (min-width: 480px) {
  .alerts .toast {
    width: 50%;
  }
}

@media screen and (min-width: 768px) {
  .row.jh-entity-details > dt {
    margin-bottom: 15px;
  }

  .row.jh-entity-details > dd {
    border-bottom: 1px solid #eee;
    padding-left: 180px;
    margin-left: 0;
  }

  .breakFastText {
    padding-left: 5%;
    width: 300px;
  }
  .breakFastText2 {
    text-align: center;
  }
  .breakFastTextAligner {
    align-self: start;
  }
}

@media (min-width: 881px) {
  .footer > .content {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .breakFastShow {
    margin: 0 15% 0 15%;
  }
}

@media all and (min-width: 1300px) {
}

@media all and (min-width: 1500px) {
  .content.overflow {
    overflow: visible;
  }

  .content {
    /*width: 1500px; TODO: seems to break 70_complete_input view, is this really needed?*/
    margin: 0 auto;
    // overflow: hidden; // seems to break with responsive css ..  please check?
    overflow: inherit;
  }

  .newsBoxButtonText {
    display: none;
  }

  .newsBoxButtonText2 {
    display: inline-flex;
  }
  // .cFormElement .cLabel {
  //   width: 310px;
  // }

  // .cFormElement .cLabelSlider {
  //   width: 315px;
  // }
}

@media (min-width: 1500px) {
  .calc-modal .modal-dialog {
    width: 90%;
  }

  .calc-table > thead > tr > th,
  .calc-table > tbody > tr > th,
  .calc-table > tfoot > tr > th,
  .calc-table > thead > tr > td,
  .calc-table > tbody > tr > td,
  .calc-table > tfoot > tr > td {
    padding: 4px !important;
    vertical-align: middle !important;
    text-align: left;
  }

  .calc-table-input {
    padding: 7px;
  }
}

/* Huge width (large screen) - Three columns (was: 1490px)*/
@media (min-width: 1550px) {
  .contentRowInner,
  .contentRowInner2 {
    /*width: 1750px;*/
  }

  .additionalInfoBox {
    flex-direction: row;
  }

  .contentRowInner .ctPartForm,
  .contentRowInner2 .ctPartForm {
    width: 710px;
  }

  .respRowWidth {
    width: 1550px;
    display: flex;
    flex-direction: row;
  }

  .ctPartForm2 {
    /*width: 1140px;*/
    width: 100%;
    float: left;
  }

  .contentRowInner2 .ctPartDescr {
    /*margin-left: 20px;*/
  }

  .contentRowInner .ctPartImg,
  .contentRowInner2 .ctPartImg {
    width: $kv-form-image-width;
    /*width: 350px;*/
    height: 270px;
    display: flex;
    justify-content: center;
  }

  .contentRowInner .ctPartImg .cImage img,
  .contentRowInner2 .ctPartImg .cImage img,
  .contentRowInner .ctPartImg .cImage object,
  .contentRowInner2 .ctPartImg .cImage object {
    width: 270px;
    height: 270px;
  }

  .contentRowInner .ctPartDescr,
  .contentRowInner2 .ctPartDescr {
    width: 362px;
    min-height: 270px;
    margin-top: 0px;
  }

  .flexBoxInner {
    width: 710px;
  }
  .flexBoxInner.advisoryboard {
    width: 720px;
  }

  .flexBoxInnerNarrow {
    width: 450px;
  }
  .flexboxInnerMedium {
    width: 580px;
  }

  .flexBoxInnerWide {
    width: 1480px;
  }

  .flexBoxInnerWider {
    width: 1192px;
  }

  .flexBoxInner.txt,
  .pageId_Home .flexBoxInner.txt,
  .pageId_LandingPom .flexBoxInner.txt,
  .pageId_LandingAgv .flexBoxInner.txt,
  .pageId_Register .flexBoxInner.txt,
  .pageId_Accuracy .flexBoxInner.txt {
    width: 700px;
  }

  .flexBoxInner.img,
  .pageId_Home .flexBoxInner.img,
  .pageId_LandingPom .flexBoxInner.img,
  .pageId_LandingAgv .flexBoxInner.img,
  .pageId_Register .flexBoxInner.img,
  .pageId_Accuracy .flexBoxInner.img {
    width: 402px;
  }

  .flexBoxInner.marginRight,
  .pageId_Home .flexBoxInner.marginRight,
  .pageId_LandingPom .flexBoxInner.marginRight,
  .pageId_LandingAgv .flexBoxInner.marginRight,
  .pageId_Register .flexBoxInner.marginRight,
  .pageId_Accuracy .flexBoxInner.marginRight {
    margin-right: 90px;
  }

  .pageId_Register .flexBoxInner.left1 {
    width: 350px;
    background-color: #ffffff;
    margin-right: 10px;
    padding: 10px;
  }

  .pageId_Register .flexBoxInner.right1 {
    width: 350px;
    background-color: #ffffff;
    padding: 10px;
  }

  /* Content box */
  .flexBoxInner.box {
    text-align: left;
    margin-top: 10px;
    background-color: #ffffff;
    padding: 5px;
    width: 710px;
  }

  .newsBoxButton {
    width: 175px;
    margin-left: 2%;
    vertical-align: top;
    text-overflow: ellipsis;
  }
  .newsBoxButtonText {
    display: inline-flex;
  }

  .newsBoxButtonText2 {
    display: none;
  }
}

/* ==========================================================================
Min Width End
========================================================================== */

/* ==========================================================================
Max Width Start
========================================================================== */

@media (max-width: 300px) {
  .WKHeader {
    visibility: hidden;
  }
}

@media (max-width: 512px) {
  .kennwerteQuickNav {
    display: flex;
    justify-content: center;
  }

  .kennwerteQuickNav > li > a {
    padding: 5px 5px;
  }

  .kennwerteQuickNav > li > a img,
  .kennwerteQuickNav > li > a object {
    max-width: 35px;
    max-height: 35px;
    width: 35px;
    height: 35px;
  }

  .kennwerteQuickNavLogo {
    padding: 6px 6px;
  }
  .btn-lg {
    padding: 9px 9px;
  }
}

@media screen and (max-width: 599px) {
  .greyBoxComplexity {
    height: 0px;
  }

  .greyBoxComplexityInsur {
    height: 0px;
  }
  .greyBoxComplexityInsurGvbs {
    height: 0px;
  }

  .greyBoxQuality,
  .greyBoxQuality-reference,
  .greyBoxQualityOpCost,
  .greyBoxQualityOpCost2,
  .greyBoxRebuild {
    height: 0px;
  }

  .greyBoxQualityInsur {
    height: 0px;
  }

  .kw-team-row {
    display: flex;
    /* flex-direction: column; */
    flex-flow: column wrap;
  }

  .kw-contact-btn {
    right: 10px;
    bottom: 4%;
  }

  /*to make sure we dont display the bubble*/
  .kw-new-product {
    display: none;
  }

  /*to make sure we dont display the text inside the bubble*/
  .kw-new-product > span {
    display: none;
  }

  .centeredVerticallyRow span {
    /* The vertical alignment magic */
    display: inline-block;
    vertical-align: middle;
    width: 50%; /* Width controls number of elements per row */
  }

  .kw-price-table-cell {
    width: 51%;
  }

  .kw-price-table-cell-small {
    width: 50%;
  }

  .kw-price-table-cell-empty {
    width: 0%;
  }
}

@media (max-width: 767px) {
  .breakFastContainer {
    flex-direction: column;
  }
  .breakFastSignUp {
    /*order:1;*/
  }
  .breakFastText {
    /*order:1;*/
    max-width: 560px;
    text-align: left;
    padding-left: 0;
    /*margin-bottom: 10px;*/
    margin: 10px 0px;
  }

  .breakfastText2 {
    text-align: left;
  }

  .kennwerteQuickNav > li > a > figcaption {
    display: none;
  }

  .navbar-collapse li a {
    -webkit-transition: opacity 100ms ease-in-out;
    -moz-transition: opacity 100ms ease-in-out;
    -o-transition: opacity 100ms ease-in-out;
    transition: opacity 100ms ease-in-out;
    opacity: 0;
  }

  .navbar-collapse.in li a {
    -webkit-transition: opacity 400ms ease-in-out;
    -moz-transition: opacity 400ms ease-in-out;
    -o-transition: opacity 400ms ease-in-out;
    transition: opacity 400ms ease-in-out;
    opacity: 1;
  }

  .navbar-collapse,
  .navbar-collapse.in {
    overflow-y: visible;
    border-bottom-color: rgba(255, 255, 255, 1);
    border-bottom-width: 1px;
    border-bottom-style: solid;
    margin-top: -7px;
    background-color: rgba(0, 0, 0, 0.9);
  }

  .navbar-default .navbar-nav > a,
  .navbar-default .navbar-nav > a:hover,
  .navbar-default .navbar-nav > a:focus {
    color: #fff;
  }

  .navbar-default .navbar-nav > .open > a,
  .navbar-default .navbar-nav > .open > a:hover,
  .navbar-default .navbar-nav > .open > a:focus {
    background-color: rgba(187, 124, 122, 1);
    color: #fff;
  }

  .navbar-default .navbar-nav .open .dropdown-menu > li > a {
    color: #fff;
  }

  .navbar-default .navbar-nav .open .dropdown-menu > li > a:hover {
    background-color: rgba(187, 124, 122, 0.5);
    color: #fff;
  }

  .navbar-default .navbar-nav .open .dropdown-menu > .active > a,
  .navbar-default .navbar-nav .open .dropdown-menu > .active > a:hover,
  .navbar-default .navbar-nav .open .dropdown-menu > .active > a:focus {
    background-color: rgba(187, 124, 122, 0.5);
    color: #fff;
  }

  .navbar-default .navbar-nav .open .dropdown-menu > .buy > a,
  .navbar-default .navbar-nav .open .dropdown-menu > .buy > a:hover,
  .navbar-default .navbar-nav .open .dropdown-menu > .buy > a:focus {
    background-color: rgba(149, 187, 122, 0.5);
    color: #d50909;
  }

  .cExplanation {
    display: none;
  }
}

/**
* this is for Ipad otherwise kennwerte Quick Nav would be collapsed into one.
*/
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .kennwerteQuickNav {
    /*display: flow-root;*/
    /*justify-content: center;*/
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .kennwerteQuickNav {
    /*display: flow-root;*/
    display: inherit !important;
  }

  .kennwerteQuickNav > li > a {
    padding: 5px 5px;
  }
}

/* Responsive rule for Quick Navigation bar*/
@media (max-width: 768px) and (min-width: 513px) {
  .kennwerteQuickNav {
    /*display: flow-root;*/
    display: flex;
    justify-content: center;
  }

  .kennwerteQuickNav > li > a {
    padding: 5px 5px;
  }
}

@media all and (max-width: 790px) {
  .left {
  }

  .right {
  }

  .pageId_Home .long,
  .pageId_LandingPom .long,
  .pageId_LandingAgv .long,
  .pageId_ProcessForm .long,
  .pageId_Register .long,
  .pageId_Accuracy .long,
  .pageId_RebuildForm .long {
    width: 100%;
    margin: 0 0 20px 0px;
  }

  .pageId_Home .short,
  .pageId_LandingPom .short,
  .pageId_LandingAgv .short,
  .pageId_ProcessForm .short,
  .pageId_Register .short,
  .pageId_Accuracy .short,
  .pageId_RebuildForm .short {
    width: 100%;
  }
}

@media all and (max-width: 940px) {
  .footer > .content {
    width: 100%;
    padding-top: 20px;
    padding-bottom: 10px;
    display: block;
    justify-content: inherit;
    align-items: inherit;
  }

  .socialButtonsWrapper {
    clear: both;
    float: none;
    width: 100%;
    height: auto;
  }

  .footerTextWrapper {
    clear: both;
    float: none;
    width: 100%;
    height: auto;
  }

  .contactButtonWrapper {
    clear: both;
    float: none;
    width: 100%;
    padding-top: 18px;
    padding-bottom: 18px;
    height: auto;
  }

  .footerSpacer {
    display: none;
    clear: both;
  }

  .content {
    width: 100%;
  }

  .contactButton {
    margin: 0 auto;
    clear: both;
    float: none;
  }

  .socialButtons {
    margin: 0 auto;
    float: none;
    clear: both;
    width: 176px;
  }
}

@media all and (max-width: 1100px) {
  .pageId_Home .long,
  .pageId_LandingPom .long,
  .pageId_LandingAgv .long,
  .pageId_ProcessForm .long,
  .pageId_Register .long,
  .pageId_Accuracy .long {
    width: 40%;
  }

  .pageId_Home .short,
  .pageId_LandingPom .short,
  .pageId_LandingAgv .short,
  .pageId_ProcessForm .short,
  .pageId_Register .short,
  .pageId_Accuracy .short {
    width: 55%;
  }
  .kv-team-img {
    height: 85px;
    width: 85px;
  }
}

@media (max-width: 1279px) {
  .calc-modal .modal-dialog {
    width: 99%;
  }

  .calc-table > thead > tr > th,
  .calc-table > tbody > tr > th,
  .calc-table > tfoot > tr > th,
  .calc-table > thead > tr > td,
  .calc-table > tbody > tr > td,
  .calc-table > tfoot > tr > td {
    padding: 2px !important;
    vertical-align: middle !important;
    text-align: left;
  }

  .calc-table-input {
    padding: 2px;
  }

  .newsBoxButton {
    width: 110px;
    margin-left: 2%;
    vertical-align: top;
    text-overflow: ellipsis;
  }

  .newsBoxButtonText {
    display: none;
  }

  .newsBoxButtonText2 {
    display: inline-flex;
  }
  .kw-new-product.kw-new-product-3 {
    top: 22%;
    right: 17%;
    height: 45%;
    margin-right: 2px;
  }
}

/* ==========================================================================
Max Width End
========================================================================== */

/* ==========================================================================
Min and Max Width Start
========================================================================== */

/*
Small width (phone) - force landscape
*/
@media only screen and (min-width: 0px) and (max-width: 599px) and (orientation: portrait) {
  .video-container {
    transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
  }

  .sectionId_userfeedback #userLogo,
  .sectionId_userfeedback2 #userLogo {
    width: 69px;
  }

  .sectionId_userfeedback p,
  .sectionId_userfeedback2 p {
    width: 100%;
  }
}

/* Small width (phone) - One column */
@media (min-width: 0px) and (max-width: 599px) {
  .h1 {
    font-size: 28px;
  }
  .h2 {
    font-size: 25px;
  }
  .h3 {
    font-size: 22px;
  }
  .h4 {
    font-size: 20px;
  }

  .breakFastShow {
    margin: 0 0 0 0;
  }
  .contentRowInner {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .contentRowInner2 {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .bannerImg h1 {
    font-size: 28px;
    white-space: normal;
  }

  .bannerTitle h1 {
    font-size: 28px;
    white-space: normal;
  }

  h3.bannerTitle {
    font-size: 25px;
    white-space: normal;
    text-align: left;
    white-space: nowrap;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .bannerTextBox {
    margin-bottom: 72px;
  }

  .bannerModeFlex {
    margin-top: -35px;
  }

  .bannerModeRealestatesOverview {
    margin-top: -75px;
  }

  .AdditionalInputBox {
    height: 98px !important;
  }

  .cFormElement {
    height: 68px;
  }

  .contentRowInner .ctPartImg,
  .contentRowInner2 .ctPartImg {
    display: none;
  }

  .contentRowInner .ctPartDescr,
  .contentRowInner2 .ctPartDescr {
    display: none;
  }
  .cFormElement .cLabel2 {
    height: fit-content;
    width: 260px;
    /*margin-bottom: 10px;*/
    float: left;
  }
  .cFormElement .cLabel {
    height: fit-content;
    width: 270px;
    /*margin-bottom: 10px;*/
    float: left;
  }
  .cFormElement .cLabelSlider,
  .cFormElement .cLabelAdvancedSlider {
    height: 30px;
    width: 270px;
    /*margin-bottom: 10px;*/
    float: left;
  }

  .cFormElement .cInputElement {
    clear: both; /*force new line for input */
  }

  .cFormElement.usageTypes {
    clear: left; /*force new line for input */
  }

  .cInfoButton {
    margin-top: -8px;
    margin-left: -8px;
  }

  .cInfoButton .md-button {
    transform: scale(0.6);
  }

  .cImage {
    margin-top: 33px;
  }

  #user-headline-lukb {
    display: inline-block;
  }

  #user-title-lukb {
    width: 100%;
    display: inline-block;
  }

  #userLogo-lukb {
    height: 35px;
    width: auto;
    padding-bottom: 5px;
    border-radius: 0%;
    vertical-align: bottom;
  }

  .lukb {
    line-height: 0;
    height: auto;
    display: block;
    padding-top: 5px;
  }

  /*.userfeedback {*/
  /*    !*display: flex;*!*/
  /*    width: 100%;*/
  /*    height: 100%;*/
  /*}*/
  /*.userfeedback p {*/
  /*    height: 100%;*/
  /*    width: 50%;*/
  /*    padding-top: 15px;*/
  /*    padding-left: 5px;*/
  /*    padding-right: 5px;*/
  /*    display: inline-block;*/
  /*}*/
  /*.userfeedbackLogo {*/
  /*    !*line-height: 100px;*!*/
  /*    !*height: 100px;*!*/
  /*    display: inline-block;*/
  /*}*/
  .cImage img,
  .cImage object {
    width: 150px;
    height: 150px;
    float: none;
    clear: both;
  }
  .cImage img,
  .cImage object {
    border-radius: 50%;
  }

  .cDescription {
    margin-left: 12px;
    margin-right: 12px;
    overflow: hidden;
  }

  .cAdditionalInfo {
    height: auto;
  }

  .flexBoxInner {
    width: 100%;
    margin-left: 10px;
    margin-right: 10px;
  }

  .flexBoxInnerNarrow,
  .flexboxInnerMedium {
    width: 100%;
    margin-left: 10px;
    margin-right: 10px;
  }

  .flexBoxInnerWide {
    width: 100%;
    margin-left: 10px;
    margin-right: 10px;
  }

  .flexBoxOuter,
  .pageId_Home .flexBoxOuter,
  .pageId_LandingPom .flexBoxOuter,
  .pageId_LandingAgv .flexBoxOuter,
  .pageId_Register .flexBoxOuter,
  .pageId_Accuracy .flexBoxOuter {
    padding-left: 10px;
    padding-right: 10px;
  }

  .flexBoxInner.txt,
  .pageId_Home .flexBoxInner.txt,
  .pageId_LandingPom .flexBoxInner.txt,
  .pageId_LandingAgv .flexBoxInner.txt,
  .pageId_Register .flexBoxInner.txt,
  .pageId_Accuracy .flexBoxInner.txt {
    width: 100%;
  }

  .flexBoxInner.img,
  .pageId_Home .flexBoxInner.img,
  .pageId_LandingPom .flexBoxInner.img,
  .pageId_LandingAgv .flexBoxInner.img,
  .pageId_Register .flexBoxInner.img,
  .pageId_Accuracy .flexBoxInner.img {
    display: none;
  }

  .flexBoxInner.marginRight,
  .pageId_Home .flexBoxInner.marginRight,
  .pageId_LandingPom .flexBoxInner.marginRight,
  .pageId_LandingAgv .flexBoxInner.marginRight,
  .pageId_Register .flexBoxInner.marginRight,
  .pageId_Accuracy .flexBoxInner.marginRight {
    margin-right: 0px;
  }

  .pageId_Register .flexBoxInner.left1 {
    width: 100%;
    background-color: #ffffff;
    padding: 10px;
    margin-bottom: 10px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .pageId_Register .flexBoxInner.right1 {
    width: 100%;
    background-color: #ffffff;
    padding: 10px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .pageId_Register .flexBoxOuter {
    display: flex;
    flex-direction: column;
  }

  .pageId_Register .flexBoxInner {
    align-self: center;
  }

  .pageId_Register .flexBoxInner.box {
    text-align: left;
    margin-top: 10px;
    background-color: #ffffff;
    padding: 5px;
    width: 100%;
    margin-left: 10px;
    margin-right: 10px;
  }

  .WKHeader {
    overflow: hidden;
  }
}

/*
@media (min-width: 555px) and (max-width: 599px) {
  .greyBoxComplexity {
       height: 330px;
   }

  .greyBoxComplexity2 {
      height: 265px;
  }

  .greyBoxQuality {
      height: 398px;
  }

  .greyBoxQuality2 {
      height: 330px;
  }
}
*/

/*
@media (min-width: 555px) and (max-width: 599px) {
  .highValue {
      margin: 0px 1px;
  }
}
*/

/* Medium width (tablet) special size small */
@media (min-width: 600px) and (max-width: 767px) {
  .bannerModeRealestatesOverview {
    margin-top: -75px;
  }

  .kw-new-product {
    display: none;
  }

  .kw-new-product > span {
    display: none;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
  .pageId_RebuildForm .cFormElement .cLabel {
    height: fit-content;
    width: 200px;
    float: left;
  }

  .pageId_RebuildForm .sliderBox {
    visibility: hidden;
  }

  .sectionId_state_analysis .contentRowInner2 {
    display: inline-flex;
  }

  .pageId_RebuildForm .cFormElement .cInputElement,
  .analysisSliders {
    width: 200px;
    float: left;
  }

  .boxplot {
    position: inherit;
  }

  .sectionId_rebuild_additions .cInputElement {
    display: inline-flex;
  }
}

@media only screen and (min-device-width: 1366px) and (max-device-width: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
  .pageId_RebuildForm .cFormElement .cLabel {
    height: fit-content;
    width: 200px;
    float: left;
  }

  .pageId_RebuildForm .sliderBox {
    visibility: hidden;
  }

  .sectionId_state_analysis .contentRowInner2 {
    display: inline-flex;
  }

  .pageId_RebuildForm .cFormElement .cInputElement,
  .analysisSliders {
    width: 200px;
    float: left;
  }

  .boxplot {
    position: inherit;
  }

  .sectionId_rebuild_additions .cInputElement {
    display: inline-flex;
  }
}

/* Medium width (tablet) - One column */
@media (min-width: 600px) and (max-width: 1023px) {
  .contentRowInner,
  .contentRowInner2 {
    flex-direction: column;
  }

  .bannerImg h1 {
    font-size: 34px;
    white-space: pre-line;
  }
  .bannerImg h3 {
    white-space: pre-line;
  }

  .bannerTextBox {
    margin-bottom: 32px;
  }

  .bannerModeContentRow {
    margin-top: 210px;
  }

  //.contentRowInner .ctPartForm,
  //.contentRowInner2 .ctPartForm {
  //width: 685px;
  //}

  .respRowWidth {
    width: 1000px;
  }

  .contentRowInner .ctPartImg,
  .contentRowInner2 .ctPartImg {
    margin-top: 12px;
  }

  .contentRowInner .ctPartImg .cImage img,
  .contentRowInner2 .ctPartImg .cImage img,
  .contentRowInner .ctPartImg .cImage object,
  .contentRowInner2 .ctPartImg .cImage object {
    width: 200px;
    height: 200px;
  }

  .AdditionalInputBox {
    /*height: 68px;*/
  }

  .contentRowInner .ctPartDescr,
  .contentRowInner2 .ctPartDescr {
    float: none;
    max-width: 500px;
  }

  .cFormElement .cLabel2 {
    height: fit-content;
    width: 232px;
    float: left;
  }

  .cFormElement .cLabel {
    height: fit-content;
    width: 350px;
    float: left;
  }

  .pageId_RebuildForm .cFormElement .cLabel {
    height: fit-content;
    width: 200px;
    float: left;
  }

  .cFormElement .cLabelSlider,
  .cFormElement .cLabelAdvancedSlider {
    /*height: fit-content;*/
    width: 300px;
    float: left;
  }

  .flexBoxInner {
    width: 560px;
  }

  /*.breakFastText*/
  .breakFastContainer {
    /*width: 560px;*/
    margin: 0;
  }
  .breakFastText {
    /*width:560px;*/
  }

  .flexBoxInnerNarrow {
    width: 450px;
  }

  .flexBoxInnerWide {
    width: 560px;
  }

  .flexBoxInnerWider {
    width: 500px;
  }

  .flexBoxOuter,
  .pageId_Home .flexBoxOuter,
  .pageId_LandingPom .flexBoxOuter,
  .pageId_LandingAgv .flexBoxOuter,
  .pageId_Register .flexBoxOuter,
  .pageId_Accuracy .flexBoxOuter {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .flexBoxInner,
  .pageId_Home .flexBoxInner,
  .pageId_LandingPom .flexBoxInner,
  .pageId_LandingAgv .flexBoxInner,
  .pageId_Register .flexBoxInner,
  .pageId_Accuracy .flexBoxInner {
    align-self: center;
  }

  .flexBoxInner.txt,
  .pageId_Home .flexBoxInner.txt,
  .pageId_LandingPom .flexBoxInner.txt,
  .pageId_LandingAgv .flexBoxInner.txt,
  .pageId_Register .flexBoxInner.txt,
  .pageId_Accuracy .flexBoxInner.txt {
    width: 500px;
    order: 1;
  }

  .flexBoxInner.img,
  .pageId_Home .flexBoxInner.img,
  .pageId_LandingPom .flexBoxInner.img,
  .pageId_LandingAgv .flexBoxInner.img,
  .pageId_Register .flexBoxInner.img,
  .pageId_Accuracy .flexBoxInner.img {
    width: 300px;
    margin-top: 15px;
    order: 2;
  }

  .flexBoxInner.marginRight,
  .pageId_Home .flexBoxInner.marginRight,
  .pageId_LandingPom .flexBoxInner.marginRight,
  .pageId_LandingAgv .flexBoxInner.marginRight,
  .pageId_Register .flexBoxInner.marginRight,
  .pageId_Accuracy .flexBoxInner.marginRight {
    margin-right: 0px;
  }

  .pageId_Register .flexBoxInner.left1 {
    width: 350px;
    background-color: #ffffff;
    padding: 10px;
    margin-bottom: 10px;
  }

  .pageId_Register .flexBoxInner.right1 {
    width: 350px;
    background-color: #ffffff;
    padding: 10px;
  }

  .pageId_Register .flexBoxOuter {
    display: flex;
    flex-direction: column;
  }

  .pageId_Register .flexBoxInner {
    align-self: center;
  }

  .pageId_Register .flexBoxInner.box {
    text-align: left;
    margin-top: 10px;
    background-color: #ffffff;
    padding: 5px;
    width: 350px;
  }
}

@media (min-width: 600px) {
  .pageId_Accuracy .verticalSpacer {
    margin-top: 80px !important;
  }

  .nav .nav-pills .nav-justified .kv-nav {
    display: table-cell;
    width: 1%;
  }

  .nav .kv-nav li.nav-item figcaption {
    display: inline-flex;
  }
  .pageId_Home .nav > li.nav-item > a {
    height: 120px;
  }

  /*.userfeedback {*/
  /*    display: inline-flex;*/
  /*}*/
  /*.userfeedback p {*/
  /*    height: 100%;*/
  /*    width: 100%;*/
  /*    padding-top: 15px;*/
  /*    padding-left: 5px;*/
  /*    padding-right: 5px;*/
  /*    display: inline-block;*/
  /*}*/
}

@media (max-width: 599px) {
  .pageId_Accuracy .verticalSpacer {
    margin-top: 0;
  }
}

/* adjust this when elements in navbar are renamed, added or removed */
@media (min-width: 768px) and (max-width: 1350px) {
  .hidden-text {
    display: none !important;
  }
}

@media (max-width: 879px) {
  .kw-new-product {
    display: none;
  }

  .kw-new-product > span {
    display: none;
  }

  .swissbau1,
  .swissbau1-1 {
    font-size: 35px;
  }
  .swissbau2 {
    bottom: -85%;
  }
  .swissbau3 {
    bottom: -135%;
  }
}

@media (min-width: 880px) and (max-width: 999px) {
  .kw-new-product {
    display: none;
  }

  .kw-new-product > span {
    display: none;
  }
}

@media (min-width: 879px) and (max-width: 1350px) {
  .hidden-text {
    display: none !important;
  }
  .swissbau1,
  .swissbau1-1 {
    font-size: 35px;
  }
  .swissbau2 {
    bottom: -85%;
  }
  .swissbau3 {
    bottom: -135%;
  }
}

/* Large width (small screen) - Two columns */
@media (min-width: 1024px) and (max-width: 1549px) {
  .bannerImg h1 {
    font-size: 48px;
  }

  .contentRowInner {
  }
  .contentRowInner2 {
  }

  .contentRowInner .ctPartForm,
  .contentRowInner2 .ctPartForm {
    width: 700px;
  }

  .contentRowInner .ctPartImg,
  .contentRowInner2 .ctPartImg {
    width: calc($kv-form-image-width * 29 / 36); //290px;
    height: 200px;
  }

  .contentRowInner .ctPartImg .cImage img,
  .contentRowInner2 .ctPartImg .cImage img,
  .contentRowInner .ctPartImg .cImage object,
  .contentRowInner2 .ctPartImg .cImage object {
    width: 200px;
    height: 200px;
  }

  .contentRowInner .ctPartDescr,
  .contentRowInner2 .ctPartDescr {
    float: none;
    width: 362px;
  }

  /* Freeze minimum heights in order to ensure no bouncing of content rows  */
  #sectionId_general .contentRowInner .ctPartDescr {
    min-height: 120px;
  }

  #sectionId_geometry .contentRowInner .ctPartDescr {
    min-height: 200px;
  }

  #sectionId_state_analysis {
    min-height: 200px;
  }

  #sectionId_target {
    min-height: 200px;
  }

  #sectionId_complexity .contentRowInner .ctPartDescr {
    min-height: 240px;
  }

  #sectionId_quality .contentRowInner .ctPartDescr,
  #sectionId-small-build-cost-class .contentRowInner .ctPartDescr {
    min-height: 280px;
  }

  .flexBoxInner {
    width: 700px;
  }

  .flexBoxInner2BoxesFix {
    /* flexBoxInner was used wrongly, this class fixes those issues without breaking other stuff. */
    width: 525px !important;
  }

  .flexBoxInnerNarrow {
    width: 450px;
  }

  .flexboxInnerMedium {
    width: 500px;
  }

  .flexBoxInnerWide {
    width: 1290px;
  }

  .flexBoxInnerWider {
    width: 860px;
  }

  .flexBoxInner.txt,
  .pageId_Home .flexBoxInner.txt,
  .pageId_LandingPom .flexBoxInner.txt,
  .pageId_LandingAgv .flexBoxInner.txt,
  .pageId_Register .flexBoxInner.txt,
  .pageId_Accuracy .flexBoxInner.txt {
    width: 500px;
  }

  .flexBoxInner.img,
  .pageId_Home .flexBoxInner.img,
  .pageId_LandingPom .flexBoxInner.img,
  .pageId_LandingAgv .flexBoxInner.img,
  .pageId_Register .flexBoxInner.img,
  .pageId_Accuracy .flexBoxInner.img {
    width: 300px;
  }

  .flexBoxInner.marginRight,
  .pageId_Home .flexBoxInner.marginRight,
  .pageId_LandingPom .flexBoxInner.marginRight,
  .pageId_LandingAgv .flexBoxInner.marginRight,
  .pageId_Register .flexBoxInner.marginRight,
  .pageId_Accuracy .flexBoxInner.marginRight {
    margin-right: 60px;
  }

  .pageId_Register .flexBoxInner.left1 {
    width: 350px;
    background-color: #ffffff;
    margin-right: 10px;
    padding: 10px;
  }

  .pageId_Register .flexBoxInner.right1 {
    width: 350px;
    background-color: #ffffff;
    padding: 10px;
  }

  /* Content box */
  .flexBoxInner.box {
    text-align: left;
    margin-top: 10px;
    background-color: #ffffff;
    padding: 5px;
    width: 710px;
  }
}

@media (min-width: 1280px) and (max-width: 1499px) {
  .calc-modal .modal-dialog {
    width: 95%;
  }

  .contentRowInner .ctPartForm,
  .contentRowInner2 .ctPartForm {
    width: 710px;
  }

  .respRowWidth {
    width: 1400px;
    // @include mixAndFun.flexbox();
    flex-direction: row;
  }

  .contentRowInner .ctPartImg,
  .contentRowInner2 .ctPartImg {
    width: calc($kv-form-image-width * 5 / 6); //300px;
    height: 270px;
    // @include mixAndFun.flexbox();
    justify-content: center;
  }

  .contentRowInner .ctPartImg .cImage img,
  .contentRowInner2 .ctPartImg .cImage img,
  .contentRowInner .ctPartImg .cImage object,
  .contentRowInner2 .ctPartImg .cImage object {
    width: 270px;
    height: 270px;
  }

  .contentRowInner .ctPartDescr,
  .contentRowInner2 .ctPartDescr {
    width: 362px;
    min-height: 270px;
    margin-top: 0px;
  }

  .additionalInfoBox {
    flex-direction: row;
  }

  .calc-table > thead > tr > th,
  .calc-table > tbody > tr > th,
  .calc-table > tfoot > tr > th,
  .calc-table > thead > tr > td,
  .calc-table > tbody > tr > td,
  .calc-table > tfoot > tr > td {
    padding: 4px !important;
    vertical-align: middle !important;
    text-align: left;
  }

  .calc-table-input {
    padding: 5px;
  }

  .newsBoxButton {
    width: 110px;
    margin-left: 2%;
    vertical-align: top;
    text-overflow: ellipsis;
  }

  .newsBoxButtonText {
    display: none;
  }

  .newsBoxButtonText2 {
    display: inline-flex;
  }

  .cInputElement.slider-wrapper {
    width: $kv-form-input-width;
    float: left;
    height: 38px;
    display: block;
  }
  .sliderBox {
    position: absolute;
    padding: 0px 0px;
    //left: 309px;
    //right: 0px;
    width: $kv-form-input-width;
    margin-top: 0px;
  }

  .ref-obj .sliderBox {
    position: absolute;
    padding: 0px 0px;
    margin-top: 0px;
    left: 270px;
    right: 0px;
    width: 380px;
    margin-left: 0px;
  }

  .ref-obj .cFormElement div.cInputElement,
  .rebuildElementNormal {
    width: $kv-form-input-width;
    display: inline-block;
  }

  .ref-obj .cFormElementSlim .cLabel,
  .ref-obj .cFormElement div.cLabel {
    height: fit-content;
    width: 270px;
    float: left;
  }
  .kw-new-product.kw-new-product-3 {
    top: 22%;
    right: 13.5%;
    height: 45%;
  }
}

/* ==========================================================================
Min and Max Width End
========================================================================== */

/* ==========================================================================
Text box and other places used responsive css Start
============================================================================*/
@media screen and (min-width: 480px) {
  .alerts .toast {
    width: 50%;
  }
}

@media screen and (min-width: 768px) {
  .row.jh-entity-details > dt {
    margin-bottom: 15px;
  }
  .row.jh-entity-details > dd {
    border-bottom: 1px solid #eee;
    padding-left: 180px;
    margin-left: 0;
  }

  .breakFastText {
    padding-left: 5%;
    width: 300px;
  }

  .breakFastText2 {
    text-align: center;
  }

  .breakFastTextAligner {
    align-self: start;
  }
}

@media (min-width: 881px) {
  .footer > .content {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .breakFastShow {
    margin: 0 15% 0 15%;
  }
}

@media all and (min-width: 1500px) {
  .content.overflow {
    overflow: visible;
  }

  .content {
    margin: 0 auto;
    // overflow: hidden; // seems to break with responsive css ..  please check?
    overflow: inherit;
  }
  .sliderBox {
    position: absolute;
    padding: 0px 0px;
    margin-top: -30px;
    left: $kv-form-label-width;
    width: $kv-form-input-width;
    margin-left: 0px;
  }
}

@media (min-width: 1499px) {
  .calc-modal .modal-dialog {
    width: 90%;
  }

  .flexBoxInner.txt,
  .pageId_Home .flexBoxInner.txt,
  .pageId_LandingPom .flexBoxInner.txt,
  .pageId_LandingAgv .flexBoxInner.txt,
  .pageId_Register .flexBoxInner.txt,
  .pageId_Accuracy .flexBoxInner.txt {
    width: 700px;
  }
  .flexBoxInner.img,
  .pageId_Home .flexBoxInner.img,
  .pageId_LandingPom .flexBoxInner.img,
  .pageId_LandingAgv .flexBoxInner.img,
  .pageId_Register .flexBoxInner.img,
  .pageId_Accuracy .flexBoxInner.img {
    width: 402px;
  }
  .pageId_Accuracy .flexBoxInner.img,
  .pageId_Home .flexBoxInner.img,
  .pageId_LandingAgv .flexBoxInner.img,
  .pageId_LandingPom .flexBoxInner.img,
  .pageId_Register .flexBoxInner.img {
    width: 402px;
  }

  .calc-table > thead > tr > th {
    padding: 4px !important;
    vertical-align: middle !important;
    text-align: left;
  }
  .calc-table > thead > tr > td {
    padding: 4px !important;
    vertical-align: middle !important;
    text-align: left;
  }
  .calc-table > tbody > tr > th {
    padding: 4px !important;
    vertical-align: middle !important;
    text-align: left;
  }
  .calc-table > tbody > tr > td {
    padding: 4px !important;
    vertical-align: middle !important;
    text-align: left;
  }
  .calc-table > tfoot > tr > th {
    padding: 4px !important;
    vertical-align: middle !important;
    text-align: left;
  }
  .calc-table > tfoot > tr > td {
    padding: 4px !important;
    vertical-align: middle !important;
    text-align: left;
  }

  .calc-table-input {
    padding: 7px;
  }

  .bannerImg h1 {
    font-size: 48px;
  }

  .additionalInfoBox {
    flex-direction: row;
  }

  .contentRowInner .ctPartForm,
  .contentRowInner2 .ctPartForm {
    //width: 710px;
    width: 810px;
    min-width: 810px;
  }

  .label-and-input-row {
    width: 100%;
  }

  .contentRowInner .ctPartImg,
  .contentRowInner2 .ctPartImg {
    width: calc($kv-form-image-width * 5 / 6); //300px;
    height: 270px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    position: relative;
    left: 20px;
  }

  .contentRowInner .ctPartImg .cImage img,
  .contentRowInner2 .ctPartImg .cImage img,
  .contentRowInner .ctPartImg .cImage object,
  .contentRowInner2 .ctPartImg .cImage object,
  .calcRowImageGallery {
    width: 270px;
    height: 270px;
  }

  .contentRowInner .ctPartDescr,
  .contentRowInner2 .ctPartDescr {
    width: 362px;
    min-height: 270px;
    margin-top: 0px;
  }

  .respRowWidth {
    width: 1500px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: row;
  }
}

@media (min-width: 1550px) {
  .additionalInfoBox {
    flex-direction: row;
  }

  .contentRowInner .ctPartForm {
    width: 810px;
  }
  .contentRowInner .ctPartImg {
    width: 360px;
    height: 270px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
  }
  .contentRowInner .ctPartImg .cImage img,
  .contentRowInner .ctPartImg .calcRowImageGallery img {
    width: 270px;
    height: 270px;
  }
  .contentRowInner .ctPartImg .cImage object,
  .contentRowInner .ctPartImg .calcRowImageGallery object {
    width: 270px;
    height: 270px;
  }
  .contentRowInner .ctPartDescr {
    width: $kv-form-description-width;
    min-height: 270px;
    margin-top: 0px;
  }

  .contentRowInner2 .ctPartForm {
    width: 710px;
  }
  .contentRowInner2 .ctPartDescr {
    width: 362px;
    min-height: 270px;
    margin-top: 0px;
  }
  .contentRowInner2 .ctPartImg {
    width: 430px;
    height: 270px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
  }
  .contentRowInner2 .ctPartImg .cImage img,
  .contentRowInner2 .ctPartImg .calcRowImageGallery img {
    width: 270px;
    height: 270px;
  }
  .contentRowInner2 .ctPartImg .cImage object,
  .contentRowInner2 .ctPartImg .calcRowImageGallery object {
    width: 270px;
    height: 270px;
  }

  .respRowWidth {
    width: 1550px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: row;
  }

  .ctPartForm2 {
    width: 1163px;
    float: left;
  }

  .flexBoxInner {
    width: 700px;
  }

  .flexBoxInner.advisoryboard {
    width: 720px;
  }

  .flexBoxInnerNarrow {
    width: 450px;
  }

  .flexboxInnerMedium {
    width: 580px;
  }

  .flexBoxInnerWide {
    width: 1480px;
  }

  .flexBoxInnerWider {
    width: 1192px;
  }

  .flexBoxInner.txt,
  .pageId_Home .flexBoxInner.txt {
    width: 700px;
  }
  .flexBoxInner.img,
  .pageId_Home .flexBoxInner.img {
    width: 402px;
  }
  .flexBoxInner.marginRight,
  .pageId_Home .flexBoxInner.marginRight {
    margin-right: 90px;
  }

  .pageId_LandingPom .flexBoxInner.txt {
    width: 700px;
  }
  .pageId_LandingPom .flexBoxInner.img {
    width: 402px;
  }
  .pageId_LandingPom .flexBoxInner.marginRight {
    margin-right: 90px;
  }

  .pageId_LandingAgv .flexBoxInner.txt {
    width: 700px;
  }
  .pageId_LandingAgv .flexBoxInner.img {
    width: 402px;
  }
  .pageId_LandingAgv .flexBoxInner.marginRight {
    margin-right: 90px;
  }

  .pageId_Register .flexBoxInner.txt {
    width: 700px;
  }
  .pageId_Register .flexBoxInner.img {
    width: 402px;
  }
  .pageId_Register .flexBoxInner.marginRight {
    margin-right: 90px;
  }
  .pageId_Register .flexBoxInner.left1 {
    width: 350px;
    background-color: #ffffff;
    margin-right: 10px;
    padding: 10px;
  }
  .pageId_Register .flexBoxInner.right1 {
    width: 350px;
    background-color: #ffffff;
    padding: 10px;
  }

  .pageId_Accuracy .flexBoxInner.txt {
    width: 700px;
  }
  .pageId_Accuracy .flexBoxInner.img {
    width: 402px;
  }
  .pageId_Accuracy .flexBoxInner.marginRight {
    margin-right: 90px;
  }

  .flexBoxInner.box {
    text-align: left;
    margin-top: 10px;
    background-color: #ffffff;
    padding: 5px;
    width: 710px;
  }

  .newsBoxButton {
    width: 166px;
    margin-left: 2%;
    vertical-align: top;
    text-overflow: ellipsis;
  }
}

@media (max-width: 300px) {
  .WKHeader {
    visibility: hidden;
  }
}

@media (max-width: 512px) {
  .kennwerteQuickNav {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
  }
  .kennwerteQuickNav > li > a {
    padding: 5px 5px;
  }
  .kennwerteQuickNav > li > a img {
    max-width: 35px;
    max-height: 35px;
    width: 35px;
    height: 35px;
  }
  .kennwerteQuickNav > li > a object {
    max-width: 35px;
    max-height: 35px;
    width: 35px;
    height: 35px;
  }

  .kennwerteQuickNavLogo {
    padding: 6px 6px;
  }
}

@media screen and (max-width: 599px) {
  .greyBoxComplexity {
    height: 0px;
  }

  .greyBoxComplexityInsur {
    height: 0px;
  }

  .greyBoxComplexityInsurGvbs {
    height: 0px;
  }

  .greyBoxQuality,
  .greyBoxQuality-reference {
    height: 0px;
  }

  .greyBoxQualityOpCost {
    height: 0px;
  }

  .greyBoxQualityOpCost2 {
    height: 0px;
  }

  .greyBoxRebuild {
    height: 0px;
  }

  .greyBoxQualityInsur {
    height: 0px;
  }

  .kw-team-row {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-flow: column wrap;
  }

  .kw-team-card {
    max-height: 300px;
    width: 100%;
    aspect-ratio: auto;
  }

  .kw-contact-btn {
    right: 10px;
    bottom: 4%;
  }

  .kw-new-product {
    display: none;
  }

  .kw-new-product > span {
    font-weight: bold;
    top: 18%;
    right: 18%;
    left: 14%;
    text-align: center;
    position: inherit;
    transform: rotate(-13grad);
    letter-spacing: 0;
    font-size: 26px;
    word-break: break-word;
    white-space: nowrap;
  }

  .centeredVerticallyRow span {
    display: inline-block;
    vertical-align: middle;
    width: 50%;
  }

  .kw-price-table-cell {
    width: 51%;
  }

  .kw-price-table-cell-small {
    width: 50%;
  }

  .kw-price-table-cell-empty {
    width: 0%;
  }
}

@media (max-width: 767px) {
  .breakFastContainer {
    flex-direction: column;
  }

  .breakFastText {
    max-width: 560px;
    text-align: left;
    padding-left: 0;
    margin: 10px 0px;
  }

  .breakfastText2 {
    text-align: left;
  }

  .kennwerteQuickNav > li > a > figcaption {
    display: none;
  }

  .navbar-collapse {
    overflow-y: visible;
    border-bottom-color: white;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    margin-top: -7px;
    background-color: rgba(0, 0, 0, 0.9);
  }
  .navbar-collapse li a {
    -webkit-transition: opacity 100ms ease-in-out;
    -moz-transition: opacity 100ms ease-in-out;
    -o-transition: opacity 100ms ease-in-out;
    transition: opacity 100ms ease-in-out;
    opacity: 0;
  }

  .navbar-collapse.in {
    overflow-y: visible;
    border-bottom-color: white;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    margin-top: -7px;
    background-color: rgba(0, 0, 0, 0.9);
  }
  .navbar-collapse.in li a {
    -webkit-transition: opacity 400ms ease-in-out;
    -moz-transition: opacity 400ms ease-in-out;
    -o-transition: opacity 400ms ease-in-out;
    transition: opacity 400ms ease-in-out;
    opacity: 1;
  }

  .navbar-default .navbar-nav > a {
    color: #fff;
  }
  .navbar-default .navbar-nav > a:hover {
    color: #fff;
  }
  .navbar-default .navbar-nav > a:focus {
    color: #fff;
  }
  .navbar-default .navbar-nav > .open > a {
    background-color: #bb7c7a;
    color: #fff;
  }
  .navbar-default .navbar-nav > .open > a:hover {
    background-color: #bb7c7a;
    color: #fff;
  }
  .navbar-default .navbar-nav > .open > a:focus {
    background-color: #bb7c7a;
    color: #fff;
  }
  .navbar-default .navbar-nav .open .dropdown-menu > li > a {
    color: #fff;
  }
  .navbar-default .navbar-nav .open .dropdown-menu > li > a:hover {
    background-color: rgba(187, 124, 122, 0.5);
    color: #fff;
  }
  .navbar-default .navbar-nav .open .dropdown-menu > .active > a {
    background-color: rgba(187, 124, 122, 0.5);
    color: #fff;
  }
  .navbar-default .navbar-nav .open .dropdown-menu > .active > a:hover {
    background-color: rgba(187, 124, 122, 0.5);
    color: #fff;
  }
  .navbar-default .navbar-nav .open .dropdown-menu > .active > a:focus {
    background-color: rgba(187, 124, 122, 0.5);
    color: #fff;
  }
  .navbar-default .navbar-nav .open .dropdown-menu > .buy > a {
    background-color: rgba(149, 187, 122, 0.5);
    color: #d50909;
  }
  .navbar-default .navbar-nav .open .dropdown-menu > .buy > a:hover {
    background-color: rgba(149, 187, 122, 0.5);
    color: #d50909;
  }
  .navbar-default .navbar-nav .open .dropdown-menu > .buy > a:focus {
    background-color: rgba(149, 187, 122, 0.5);
    color: #d50909;
  }

  .cExplanation {
    display: none;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .kennwerteQuickNav {
    display: inherit !important;
  }
  .kennwerteQuickNav > li > a {
    padding: 5px 5px;
  }
}

@media (max-width: 768px) and (min-width: 513px) {
  .kennwerteQuickNav {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
  }
  .kennwerteQuickNav > li > a {
    padding: 5px 5px;
  }
}

@media all and (max-width: 790px) {
  .pageId_Home .long {
    width: 100%;
    margin: 0 0 20px 0px;
  }
  .pageId_Home .short {
    width: 100%;
  }

  .pageId_LandingPom .long {
    width: 100%;
    margin: 0 0 20px 0px;
  }
  .pageId_LandingPom .short {
    width: 100%;
  }

  .pageId_LandingAgv .long {
    width: 100%;
    margin: 0 0 20px 0px;
  }
  .pageId_LandingAgv .short {
    width: 100%;
  }

  .pageId_ProcessForm .long {
    width: 100%;
    margin: 0 0 20px 0px;
  }
  .pageId_ProcessForm .short {
    width: 100%;
  }

  .pageId_Register .long {
    width: 100%;
    margin: 0 0 20px 0px;
  }
  .pageId_Register .short {
    width: 100%;
  }

  .pageId_Accuracy .long {
    width: 100%;
    margin: 0 0 20px 0px;
  }
  .pageId_Accuracy .short {
    width: 100%;
  }

  .pageId_RebuildForm .long {
    width: 100%;
    margin: 0 0 20px 0px;
  }
  .pageId_RebuildForm .short {
    width: 100%;
  }
}

@media all and (max-width: 940px) {
  .footer > .content {
    width: 100%;
    padding-top: 20px;
    padding-bottom: 10px;
    display: block;
    justify-content: inherit;
    align-items: inherit;
  }

  .socialButtonsWrapper {
    clear: both;
    float: none;
    width: 100%;
    height: auto;
  }

  .footerTextWrapper {
    clear: both;
    float: none;
    width: 100%;
    height: auto;
  }

  .contactButtonWrapper {
    clear: both;
    float: none;
    width: 100%;
    padding-top: 18px;
    padding-bottom: 18px;
    height: auto;
  }

  .footerSpacer {
    display: none;
    clear: both;
  }

  .content {
    width: 100%;
  }

  .contactButton {
    margin: 0 auto;
    clear: both;
    float: none;
  }

  .socialButtons {
    margin: 0 auto;
    float: none;
    clear: both;
    width: 176px;
  }
}

@media all and (max-width: 1100px) {
  .pageId_Home .long {
    width: 40%;
  }
  .pageId_Home .short {
    width: 55%;
  }

  .pageId_LandingPom .long {
    width: 40%;
  }
  .pageId_LandingPom .short {
    width: 55%;
  }

  .pageId_LandingAgv .long {
    width: 40%;
  }
  .pageId_LandingAgv .short {
    width: 55%;
  }

  .pageId_ProcessForm .long {
    width: 40%;
  }
  .pageId_ProcessForm .short {
    width: 55%;
  }

  .pageId_Register .long {
    width: 40%;
  }
  .pageId_Register .short {
    width: 55%;
  }

  .pageId_Accuracy .long {
    width: 40%;
  }
  .pageId_Accuracy .short {
    width: 55%;
  }
}

@media (min-width: 1024px) and (max-width: 1279px) {
  .ctPartForm {
    width: 710px;
  }

  .respRowWidth {
    width: 1100px;
    display: flex;
    flex-direction: column;
  }
}

@media (max-width: 1380px) {
  .ctPartForm2 {
    // display: flex;
    display: block;
  }

  #sectionId_state_analysis .cPartForm2 {
    flex-direction: column;
  }
}

@media (max-width: 1279px) {
  .calc-modal .modal-dialog {
    width: 99%;
  }

  .calc-table > thead > tr > th {
    padding: 2px !important;
    vertical-align: middle !important;
    text-align: left;
  }
  .calc-table > thead > tr > td {
    padding: 2px !important;
    vertical-align: middle !important;
    text-align: left;
  }
  .calc-table > tbody > tr > th {
    padding: 2px !important;
    vertical-align: middle !important;
    text-align: left;
  }
  .calc-table > tbody > tr > td {
    padding: 2px !important;
    vertical-align: middle !important;
    text-align: left;
  }
  .calc-table > tfoot > tr > th {
    padding: 2px !important;
    vertical-align: middle !important;
    text-align: left;
  }
  .calc-table > tfoot > tr > td {
    padding: 2px !important;
    vertical-align: middle !important;
    text-align: left;
  }

  .calc-table-input {
    padding: 2px;
  }

  .newsBoxButton {
    width: 110px;
    margin-left: 2%;
    vertical-align: top;
    text-overflow: ellipsis;
  }

  .newsBoxButtonText {
    display: none;
  }

  .newsBoxButtonText2 {
    display: inline-flex;
  }
}

@media only screen and (min-width: 0px) and (max-width: 599px) and (orientation: portrait) {
  .video-container {
    transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
  }

  .sectionId_userfeedback #userLogo {
    width: 69px;
  }
  .sectionId_userfeedback p {
    width: 100%;
  }

  .sectionId_userfeedback2 #userLogo {
    width: 69px;
  }
  .sectionId_userfeedback2 p {
    width: 100%;
  }
}

@media (min-width: 0px) and (max-width: 599px) {
  .h1 {
    font-size: 28px;
  }

  .h2 {
    font-size: 25px;
  }

  .h3 {
    font-size: 22px;
  }

  .h4 {
    font-size: 20px;
  }

  .breakFastShow {
    margin: 0 0 0 0;
  }

  .contentRowInner {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .contentRowInner .ctPartImg {
    display: none;
  }
  .contentRowInner .ctPartDescr {
    display: none;
  }

  .contentRowInner2 {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .contentRowInner2 .ctPartImg {
    display: none;
  }
  .contentRowInner2 .ctPartDescr {
    display: none;
  }

  .bannerImg h1 {
    font-size: 28px;
    white-space: normal;
  }

  .bannerTitle h1 {
    font-size: 28px;
    white-space: normal;
  }

  h3.bannerTitle {
    font-size: 25px;
    white-space: normal;
    text-align: left;
    white-space: nowrap;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .bannerTextBox {
    margin-bottom: 72px;
  }

  .bannerModeFlex {
    margin-top: -35px;
  }

  .bannerModeRealestatesOverview {
    margin-top: -75px;
  }

  .AdditionalInputBox {
    height: 98px !important;
  }

  .cFormElement {
    height: 68px !important;
  }
  .cFormElement .cLabel2 {
    height: fit-content;
    width: 260px;
    float: left;
  }
  .cFormElement .cLabel {
    height: fit-content;
    width: 270px;
    float: left;
  }
  .cFormElement .cLabelSlider {
    height: 30px;
    width: 270px;
    float: left;
  }
  .cFormElement .cLabelAdvancedSlider {
    height: 30px;
    width: 270px;
    float: left;
  }
  .cFormElement .cInputElement {
    clear: both;
  }

  .cFormElement.usageTypes {
    clear: left;
  }

  .cInfoButton {
    margin-top: -8px;
    margin-left: -8px;
  }
  .cInfoButton .md-button {
    transform: scale(0.6);
  }

  .cImage,
  .calcRowImageGallery {
    margin-top: 33px;
  }
  .cImage img,
  .calcRowImageGallery img {
    width: 150px;
    height: 150px;
    float: none;
    clear: both;
  }
  .cImage object,
  .calcRowImageGallery object {
    width: 150px;
    height: 150px;
    float: none;
    clear: both;
  }

  #user-headline-lukb {
    display: inline-block;
  }

  #user-title-lukb {
    width: 100%;
    display: inline-block;
  }

  #userLogo-lukb {
    height: 35px;
    width: auto;
    padding-bottom: 5px;
    border-radius: 0%;
    vertical-align: bottom;
  }

  .lukb {
    line-height: 0;
    height: auto;
    display: block;
    padding-top: 5px;
  }

  .cDescription {
    margin-left: 12px;
    margin-right: 12px;
    overflow: hidden;
  }

  .cAdditionalInfo {
    height: auto;
  }

  .flexBoxInner {
    width: 100%;
    margin-left: 10px;
    margin-right: 10px;
  }

  .flexBoxInnerNarrow {
    width: 100%;
    margin-left: 10px;
    margin-right: 10px;
  }

  .flexboxInnerMedium {
    width: 100%;
    margin-left: 10px;
    margin-right: 10px;
  }

  .flexBoxInnerWide {
    width: 100%;
    margin-left: 10px;
    margin-right: 10px;
  }

  .pageId_Home .flexBoxOuter {
    padding-left: 10px;
    padding-right: 10px;
  }
  .flexBoxInner.txt,
  .pageId_Home .flexBoxInner.txt {
    width: 100%;
  }
  .flexBoxInner.img,
  .pageId_Home .flexBoxInner.img {
    display: none;
  }
  .flexBoxInner.marginRight,
  .pageId_Home .flexBoxInner.marginRight {
    margin-right: 0px;
  }

  .flexBoxInner.marginRight.bannerTextBox,
  .pageId_Home .flexBoxInner.marginRight.bannerTextBox {
    margin-bottom: 70px;
  }
  .nav .nav-pills .nav-justified .kv-nav {
    display: flex;
  }

  .nav .kv-nav li.nav-item figcaption {
    display: none;
  }

  .pageId_LandingPom .flexBoxOuter {
    padding-left: 10px;
    padding-right: 10px;
  }
  .pageId_LandingPom .flexBoxInner.txt {
    width: 100%;
  }
  .pageId_LandingPom .flexBoxInner.img {
    display: none;
  }
  .pageId_LandingPom .flexBoxInner.marginRight {
    margin-right: 0px;
  }

  .pageId_LandingAgv .flexBoxOuter {
    padding-left: 10px;
    padding-right: 10px;
  }
  .pageId_LandingAgv .flexBoxInner.txt {
    width: 100%;
  }
  .pageId_LandingAgv .flexBoxInner.img {
    display: none;
  }
  .pageId_LandingAgv .flexBoxInner.marginRight {
    margin-right: 0px;
  }

  .pageId_Register .flexBoxOuter {
    padding-left: 10px;
    padding-right: 10px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
  }
  .pageId_Register .flexBoxInner.txt {
    width: 100%;
  }
  .pageId_Register .flexBoxInner.img {
    display: none;
  }
  .pageId_Register .flexBoxInner.marginRight {
    margin-right: 0px;
  }
  .pageId_Register .flexBoxInner.left1 {
    width: 100%;
    background-color: #ffffff;
    padding: 10px;
    margin-bottom: 10px;
    margin-left: 10px;
    margin-right: 10px;
  }
  .pageId_Register .flexBoxInner.right1 {
    width: 100%;
    background-color: #ffffff;
    padding: 10px;
    margin-left: 10px;
    margin-right: 10px;
  }
  .pageId_Register .flexBoxInner {
    align-self: center;
  }
  .pageId_Register .flexBoxInner.box {
    text-align: left;
    margin-top: 10px;
    background-color: #ffffff;
    padding: 5px;
    width: 100%;
    margin-left: 10px;
    margin-right: 10px;
  }

  .pageId_Accuracy .flexBoxOuter {
    padding-left: 10px;
    padding-right: 10px;
  }
  .pageId_Accuracy .flexBoxInner.txt {
    width: 100%;
  }
  .pageId_Accuracy .flexBoxInner.img {
    display: none;
  }
  .pageId_Accuracy .flexBoxInner.marginRight {
    margin-right: 0px;
  }

  .WKHeader {
    overflow: hidden;
  }
}

@media (min-width: 600px) and (max-width: 767px) {
  .bannerModeRealestatesOverview {
    margin-top: -75px;
  }

  .kw-new-product {
    display: none;
  }
  .kw-new-product > span {
    display: none;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
  .pageId_RebuildForm .cFormElement .cLabel {
    height: fit-content;
    width: 200px;
    float: left;
  }
  .pageId_RebuildForm .cFormElement .cInputElement {
    width: 200px;
    float: left;
  }
  .pageId_RebuildForm .sliderBox {
    visibility: hidden;
  }

  .sectionId_state_analysis .contentRowInner2 {
    display: inline-flex;
  }

  .analysisSliders {
    width: 200px;
    float: left;
  }

  .boxplot {
    position: inherit;
  }

  .sectionId_rebuild_additions .cInputElement {
    display: inline-flex;
  }
}

@media only screen and (min-device-width: 1366px) and (max-device-width: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
  .pageId_RebuildForm .cFormElement .cLabel {
    height: fit-content;
    width: 200px;
    float: left;
  }
  .pageId_RebuildForm .cFormElement .cInputElement {
    width: 200px;
    float: left;
  }
  .pageId_RebuildForm .sliderBox {
    visibility: hidden;
  }

  .sectionId_state_analysis .contentRowInner2 {
    display: inline-flex;
  }

  .analysisSliders {
    width: 200px;
    float: left;
  }

  .boxplot {
    position: inherit;
  }

  .sectionId_rebuild_additions .cInputElement {
    display: inline-flex;
  }
}

@media (min-width: 600px) and (max-width: 1023px) {
  .respRowWidth {
    display: flex;
    flex-direction: column;
  }

  .contentRowInner {
    flex-direction: column;
  }

  .contentRowInner .ctPartImg {
    margin-top: 12px;
  }
  .contentRowInner .ctPartImg .cImage img,
  .contentRowInner .ctPartImg .calcRowImageGallery img {
    width: 200px;
    height: 200px;
  }
  .contentRowInner .ctPartImg .cImage object,
  .contentRowInner .ctPartImg .calcRowImageGallery object {
    width: 200px;
    height: 200px;
  }
  .contentRowInner .ctPartDescr {
    float: none;
    max-width: 500px;
  }

  .contentRowInner2 {
    flex-direction: column;
  }
  .contentRowInner2 .ctPartForm {
    width: 685px;
  }
  .contentRowInner2 .ctPartImg {
    margin-top: 12px;
  }
  .contentRowInner2 .ctPartImg .cImage img,
  .contentRowInner2 .ctPartImg .calcRowImageGallery img {
    width: 200px;
    height: 200px;
  }
  .contentRowInner2 .ctPartImg .cImage object,
  .contentRowInner2 .ctPartImg .calcRowImageGallery object {
    width: 200px;
    height: 200px;
  }
  .contentRowInner2 .ctPartDescr {
    float: none;
    max-width: 500px;
  }

  .bannerImg h1 {
    font-size: 34px;
  }

  .bannerTextBox {
    margin-bottom: 32px;
  }

  .bannerModeContentRow {
    margin-top: 210px;
  }

  .respRowWidth {
    width: 1000px;
  }

  .cFormElement .cLabel2 {
    height: fit-content;
    width: 232px;
    float: left;
  }
  .cFormElement .cLabel {
    height: fit-content;
    width: 350px;
    float: left;
  }
  .cFormElement .cLabelSlider {
    width: 300px;
    width: 355px;
    float: left;
  }
  .cFormElement .cLabelAdvancedSlider {
    width: 300px;
    float: left;
  }

  .pageId_RebuildForm .cFormElement .cLabel {
    height: fit-content;
    width: 200px;
    float: left;
  }

  .flexBoxInner {
    width: 560px;
  }

  .breakFastContainer {
    margin: 0;
  }

  .flexBoxInnerNarrow {
    width: 450px;
  }

  .flexBoxInnerWide {
    width: 560px;
  }

  .flexBoxInnerWider {
    width: 500px;
  }

  .pageId_Home .flexBoxOuter {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .pageId_Home .flexBoxInner {
    align-self: center;
  }
  .pageId_Home .flexBoxInner.txt {
    width: 500px;
    order: 1;
  }
  .flexBoxInner.img,
  .pageId_Home .flexBoxInner.img {
    width: 300px;
    margin-top: 15px;
    order: 2;
  }
  .pageId_Home .swissbau1,
  .pageId_Home .swissbau1-1 {
    font-size: 35px !important;
  }
  .pageId_Home .swissbau2,
  .pageId_Home .swissbau3 {
    font-size: 20px !important;
  }
  .pageId_Home .swissbau2 {
    bottom: -80% !important;
  }
  .pageId_Home .swissbau3 {
    bottom: -110% !important;
  }
  .flexBoxInner.marginRight,
  .pageId_Home .flexBoxInner.marginRight {
    margin-right: 0px;
  }

  .pageId_LandingPom .flexBoxOuter {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .pageId_LandingPom .flexBoxInner {
    align-self: center;
  }
  .pageId_LandingPom .flexBoxInner.txt {
    width: 500px;
    order: 1;
  }
  .pageId_LandingPom .flexBoxInner.img {
    width: 300px;
    margin-top: 15px;
    order: 2;
  }
  .pageId_LandingPom .flexBoxInner.marginRight {
    margin-right: 0px;
  }

  .pageId_LandingAgv .flexBoxOuter {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .pageId_LandingAgv .flexBoxInner {
    align-self: center;
  }
  .pageId_LandingAgv .flexBoxInner.txt {
    width: 500px;
    order: 1;
  }
  .pageId_LandingAgv .flexBoxInner.img {
    width: 300px;
    margin-top: 15px;
    order: 2;
  }
  .pageId_LandingAgv .flexBoxInner.marginRight {
    margin-right: 0px;
  }

  .pageId_Register .flexBoxOuter {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    flex-direction: column;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  }
  .pageId_Register .flexBoxInner {
    align-self: center;
  }
  .pageId_Register .flexBoxInner.txt {
    width: 500px;
    order: 1;
  }
  .pageId_Register .flexBoxInner.img {
    width: 300px;
    margin-top: 15px;
    order: 2;
  }
  .pageId_Register .flexBoxInner.marginRight {
    margin-right: 0px;
  }
  .pageId_Register .flexBoxInner.left1 {
    width: 350px;
    background-color: #ffffff;
    padding: 10px;
    margin-bottom: 10px;
  }
  .pageId_Register .flexBoxInner.right1 {
    width: 350px;
    background-color: #ffffff;
    padding: 10px;
  }
  .pageId_Register .flexBoxInner.box {
    text-align: left;
    margin-top: 10px;
    background-color: #ffffff;
    padding: 5px;
    width: 350px;
  }

  .pageId_Accuracy .flexBoxOuter {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .pageId_Accuracy .flexBoxInner {
    align-self: center;
  }
  .pageId_Accuracy .flexBoxInner.txt {
    width: 500px;
    order: 1;
  }
  .pageId_Accuracy .flexBoxInner.img {
    width: 300px;
    margin-top: 15px;
    order: 2;
  }
  .pageId_Accuracy .flexBoxInner.marginRight {
    margin-right: 0px;
  }
}

@media (min-width: 600px) {
  .pageId_Accuracy .verticalSpacer {
    margin-top: 80px !important;
  }
}

@media (max-width: 599px) {
  .pageId_Accuracy .verticalSpacer {
    margin-top: 0;
  }
}

@media (min-width: 768px) and (max-width: 1350px) {
  .hidden-text {
    display: none !important;
  }
}

@media (max-width: 879px) {
  .kw-new-product {
    display: none;
  }
  .kw-new-product > span {
    display: none;
  }
}

@media (min-width: 880px) and (max-width: 999px) {
  .kw-new-product {
    position: absolute;
    top: 21%;
    right: unset;
    left: 2%;
    border-radius: 50% !important;
    height: 6em;
    width: 6em;
  }
  .kw-new-product.kw-new-product-2 {
    display: none;
  }
  .kw-new-product > span {
    font-weight: bold;
    font-stretch: condensed;
    top: 6%;
    right: 23%;
    position: inherit;
    transform: rotate(-13grad);
    letter-spacing: 0px;
    font-size: 24px;
  }
  .kw-new-product.kw-new-product-3 {
    top: 22%;
    right: 13%;
    height: 45%;
    margin-right: 2px;
  }
}

@media (min-width: 879px) and (max-width: 1350px) {
  .hidden-text {
    display: none !important;
  }
}

@media (max-width: 1549px) {
  .pageId_Home .swissbau1,
  .pageId_Home .swissbau1-1 {
    font-size: 45px;
  }
  .pageId_Home .swissbau2,
  .pageId_Home .swissbau3 {
    font-size: 20px;
  }
  .pageId_Home .swissbau2 {
    bottom: -130%;
  }
  .pageId_Home .swissbau3 {
    bottom: -190%;
  }
}

@media (min-width: 1024px) and (max-width: 1499px) {
  .bannerImg h1 {
    font-size: 48px;
  }

  .contentRowInner .ctPartForm {
    width: 810px;
  }
  .contentRowInner2 .ctPartForm {
    width: 710px;
  }
  #sectionId_general .contentRowInner .ctPartDescr {
    min-height: 120px;
  }

  #sectionId_geometry .contentRowInner .ctPartDescr {
    min-height: 200px;
  }

  #sectionId_state_analysis {
    min-height: 200px;
  }

  #sectionId_target {
    min-height: 200px;
  }

  #sectionId_complexity .contentRowInner .ctPartDescr {
    min-height: 240px;
  }

  #sectionId_quality .contentRowInner .ctPartDescr {
    min-height: 280px;
  }

  #sectionId-small-build-cost-class .contentRowInner .ctPartDescr {
    min-height: 280px;
  }

  .flexBoxInner {
    width: 700px;
  }

  .flexBoxInner2BoxesFix {
    width: 525px !important;
  }

  .flexBoxInnerNarrow {
    width: 450px;
  }

  .flexboxInnerMedium {
    width: 500px;
  }

  .flexBoxInnerWide {
    width: 1290px;
  }

  .flexBoxInnerWider {
    width: 860px;
  }

  .mat-mdc-select {
    cursor: pointer;
  }

  .pageId_Home .flexBoxInner.txt {
    width: 500px;
  }
  .flexBoxInner.img,
  .pageId_Home .flexBoxInner.img {
    width: 300px;
  }
  .pageId_Home .swissbau1,
  .pageId_Home .swissbau1-1 {
    font-size: 35px;
  }
  .pageId_Home .swissbau2,
  .pageId_Home .swissbau3 {
    font-size: 20px;
  }
  .pageId_Home .swissbau2 {
    bottom: -80%;
  }
  .pageId_Home .swissbau3 {
    bottom: -125%;
  }
  .flexBoxInner.marginRight,
  .pageId_Home .flexBoxInner.marginRight {
    margin-right: 60px;
  }

  .pageId_LandingPom .flexBoxInner.txt {
    width: 500px;
  }
  .pageId_LandingPom .flexBoxInner.img {
    width: 300px;
  }
  .pageId_LandingPom .flexBoxInner.marginRight {
    margin-right: 60px;
  }

  .pageId_LandingAgv .flexBoxInner.txt {
    width: 500px;
  }
  .pageId_LandingAgv .flexBoxInner.img {
    width: 300px;
  }
  .pageId_LandingAgv .flexBoxInner.marginRight {
    margin-right: 60px;
  }

  .pageId_Register .flexBoxInner.txt {
    width: 500px;
  }
  .pageId_Register .flexBoxInner.img {
    width: 300px;
  }
  .pageId_Register .flexBoxInner.marginRight {
    margin-right: 60px;
  }
  .pageId_Register .flexBoxInner.left1 {
    width: 350px;
    background-color: #ffffff;
    margin-right: 10px;
    padding: 10px;
  }
  .pageId_Register .flexBoxInner.right1 {
    width: 350px;
    background-color: #ffffff;
    padding: 10px;
  }

  .pageId_Accuracy .flexBoxInner.txt {
    width: 500px;
  }
  .pageId_Accuracy .flexBoxInner.img {
    width: 300px;
  }
  .pageId_Accuracy .flexBoxInner.marginRight {
    margin-right: 60px;
  }

  .flexBoxInner.box {
    text-align: left;
    margin-top: 10px;
    background-color: #ffffff;
    padding: 5px;
    width: 710px;
  }
}

@media (min-width: 1280px) and (max-width: 1499px) {
  .calc-modal .modal-dialog {
    width: 95%;
  }

  .contentRowInner .ctPartForm {
    width: 810px;
  }
  .contentRowInner .ctPartImg {
    width: calc($kv-form-image-width * 2 / 3 - 40px);
    height: calc($kv-form-image-width * 2 / 3 - 40px);
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
  }
  .contentRowInner .ctPartImg .cImage img,
  .contentRowInner .ctPartImg .calcRowImageGallery img {
    width: calc($kv-form-image-width * 2 / 3 - 40px);
    height: calc($kv-form-image-width * 2 / 3 - 40px);
  }

  .contentRowInner .ctPartImg .cImage .img-box {
    width: calc($kv-form-image-width * 2 / 3 - 40px);
    height: calc($kv-form-image-width * 2 / 3 - 40px);
  }

  .contentRowInner .ctPartImg .cImage object,
  .contentRowInner .ctPartImg .calcRowImageGallery object {
    width: calc($kv-form-image-width * 2 / 3 - 40px);
    height: calc($kv-form-image-width * 2 / 3 - 40px);
  }
  .contentRowInner .ctPartDescr {
    width: calc($kv-form-description-width * 140 / 181 - 20px); //280px
    min-height: 270px;
    margin-top: 0px;
  }

  .cFormElementSlim .cLabel,
  .cFormElement div.cLabel {
    height: fit-content;
    width: 310px;
    float: left;
  }

  .cFormElement .cLabelSlider {
    height: inherit;
    width: 310px;
    float: left;
  }

  .ref-obj .cFormElement .cLabelSlider {
    height: inherit;
    width: 275px;
    float: left;
  }

  .cFormElement div.cInputElement,
  .rebuildElementNormal {
    width: $kv-form-input-width;
    float: left;
  }
  .label-and-input-row {
    width: $kv-form-input-width + 310px; //310 px from cLabel.
  }

  .contentRowInner2 .ctPartForm {
    width: 710px;
  }
  .contentRowInner2 .ctPartImg {
    width: 300px;
    height: 270px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
  }
  .contentRowInner2 .ctPartImg .cImage img,
  .contentRowInner2 .ctPartImg .calcRowImageGallery img {
    width: 270px;
    height: 270px;
  }
  .contentRowInner2 .ctPartImg .cImage object,
  .contentRowInner2 .ctPartImg .calcRowImageGallery object {
    width: 270px;
    height: 270px;
  }
  .contentRowInner2 .ctPartDescr {
    width: 300px;
    min-height: 270px;
    margin-top: 0px;
  }

  .respRowWidth {
    width: 1300px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: row;
  }

  .additionalInfoBox {
    flex-direction: row;
  }

  .calc-table > thead > tr > th {
    padding: 4px !important;
    vertical-align: middle !important;
    text-align: left;
  }
  .calc-table > thead > tr > td {
    padding: 4px !important;
    vertical-align: middle !important;
    text-align: left;
  }
  .calc-table > tbody > tr > th {
    padding: 4px !important;
    vertical-align: middle !important;
    text-align: left;
  }
  .calc-table > tbody > tr > td {
    padding: 4px !important;
    vertical-align: middle !important;
    text-align: left;
  }
  .calc-table > tfoot > tr > th {
    padding: 4px !important;
    vertical-align: middle !important;
    text-align: left;
  }
  .calc-table > tfoot > tr > td {
    padding: 4px !important;
    vertical-align: middle !important;
    text-align: left;
  }

  .calc-table-input {
    padding: 5px;
  }

  .newsBoxButton {
    width: 110px;
    margin-left: 2%;
    vertical-align: top;
    text-overflow: ellipsis;
  }

  .newsBoxButtonText {
    display: none;
  }

  .newsBoxButtonText2 {
    display: inline-flex;
  }

  .bannerModeRealestatesOverview {
    position: relative;
    right: 45%;
  }
}

.info-btn-box {
  float: right;
}

@media (min-width: 992px) {
  .dimension-modalbox.video-container {
    width: 900px !important;
  }
}

@media (min-width: 1550px) {
  .respRowWidth {
    width: 1550px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  // .cFormElement div.cLabel {
  //   width: 315px;
  // }

  // .cFormElement .cLabelSlider {
  //   width: 315px;
  // }

  //  .cFormElement .cLabelSlider {
  //   width: 320px;
  // }

  .sliderBox {
    position: absolute;
    padding: 0px 0px;
    margin-top: -30px;
    left: 350px;
    width: 380px;
    margin-left: 0px;
  }
}

@media (min-width: 1550px) and (max-width: 1609px) {
  .cFormElement div.cLabel {
    width: 292px;
  }

  .cFormElement .cLabelSlider {
    width: 292px;
  }
  .sliderBox {
    position: absolute;
    padding: 0px 0px;
    margin-top: -30px;
    left: 292px;
    width: 380px;
    margin-left: 0px;
  }
}
