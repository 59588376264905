.price-table {
  border: none !important;
  .mat-mdc-header-cell {
    color: #0000008a;
    padding: 0;
    border: none;
    font-size: 16px;
  }
  .mat-mdc-cell,
  .mat-mdc-footer-cell {
    padding: 0;
    font-size: 14px;
    border: none;
    letter-spacing: normal;
  }
}

:host ::ng-deep .price-table.mat-mdc-table {
  padding-left: 24px;
  padding-right: 24px;
  margin-top: 15px;
  background-color: inherit;
}

.price-table {
  & tr.mat-mdc-row,
  & tr.mat-mdc-footer-row,
  & tr.mat-mdc-header-row {
    height: 34px;
    border: none;
  }
}

::ng-deep .mat-mdc-table {
  width: 100%;
  box-shadow: unset;
  border: none;
  border-bottom: unset;
}

::ng-deep .mat-mdc-table th {
  font-size: inherit;
}

::ng-deep .mat-mdc-footer-cell {
  letter-spacing: normal;
}
