//importing library include media to deal intelligently with media queries :)
@import "include-media/dist/include-media";

.sliderBox {
  .box-25 {
    width: 24.58%;
  }
  .box-37 {
    width: 37%;
  }

  .de .v1 {
    display: inline-flex;
    float: left;
    margin-left: 0px;
    margin-right: 0;
    width: 50%;
    font-size: 12px;
    font-weight: normal;
    margin-top: 2px;
  }

  .de .v2 {
    display: inline-block;
    margin-left: 0;
    margin-right: 0;
    width: 50%;
    padding-left: 6%;
    font-size: 12px;
    font-weight: normal;
    margin-top: 2px;
  }

  .de .v3 {
    display: inline-block;
    margin-right: 0;
    margin-left: 0;
    width: 32%;
    padding-left: 5%;
    font-size: 12px;
    font-weight: normal;
    margin-top: 2px;
  }

  .de .v4 {
    display: inline-block;
    margin-right: 0;
    margin-left: 0;
    width: 32%;
    padding-left: 8%;
    font-size: 12px;
    font-weight: normal;
    margin-top: 2px;
  }

  .de .v5 {
    display: inline-block;
    margin-right: 0;
    margin-left: 0;
    width: 32%;
    padding-left: 11%;
    font-size: 12px;
    font-weight: normal;
    margin-top: 2px;
  }

  .de .v6 {
    display: inline-block;
    position: relative;
    margin-right: 0;
    margin-left: 0;
    width: 32%;
    padding-left: 14%;
    font-size: 12px;
    font-weight: normal;
    margin-top: 2px;
  }

  .de .v7 {
    display: inline-block;
    position: relative;
    margin-right: 0;
    margin-left: 0;
    width: 32%;
    padding-left: 17%;
    font-size: 12px;
    font-weight: normal;
    margin-top: 2px;
  }

  .de .v8 {
    display: inline-block;
    float: right;
    margin-right: 0;
    margin-left: 0;
    width: 32%;
    text-align: right;
    font-size: 12px;
    font-weight: normal;
    margin-top: 2px;
  }
}

.slider-box-value-label {
}

.sliderBox .sliderValueBox span label {
  width: 2em;
}

//$breakpoints: (
//  'xsmall': 320px,
//  'small': 599px,
//  'medium': 768px,
//  'large': 1024px,
//  'xlarge': 1280px,
//  'xxlarge': 1499px,
//  'huge': 1550px,
//);
@include media(">=huge") {
  .cInputElement .sliderBox {
    margin-left: 0px;
    width: $kv-form-input-width;
  }
}
