::ng-deep {
  /**
  seems like this is not nessecary anymore - I had some moments where the dropdown were transperent. Leaving this hear in case we encounter it again.
   */
  //.mat-mdc-select-panel {
  //  //background-color: $kw-colors-white;
  //}
  //careful cdk-overlay-pane is used on multiple elements... (for instance Dialog's are also using the overlay pane!)
  .wide-mat-select-pane .cdk-overlay-pane {
    width: $kv-form-input-width !important;
  }
  .cdk-overlay-pane:has(.wide-mat-select-pane) {
    width: $kv-form-input-width !important;
  }
  /**
  mat select arrow placement
 */
  .mat-mdc-select-arrow svg {
    fill: currentColor;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(0%, -60%) !important;
  }
  .mat-mdc-select-trigger {
    margin-top: 6px;
  }

  .mat-mdc-select-trigger {
    font-size: 14px;
  }

  /**
  mainly for usages dropdown (or similar) with the > to go to the child. with this rule the > is on the right side of the dropdown..
   */
  .mat-mdc-option .mdc-list-item__primary-text {
    width: 100%;
  }

  .mat-mdc-select-panel:not([class*="mat-elevation-z"]) {
    box-shadow:
      0 2px 4px -1px #0003,
      0 4px 5px #00000024,
      0 1px 10px #0000001f;
  }

  .rebuild-row-s {
    .compact-elevator {
      min-width: 30px;
      /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
      .mat-select-trigger {
        display: inline-flex;
        align-items: stretch;
        cursor: pointer;
        position: relative;
        box-sizing: border-box;
        width: 100%;
        text-overflow: clip;
        min-width: 30px;
        right: 9%;
        flex-wrap: nowrap;
      }
      /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
      .mat-mdc-form-field-flex .mat-mdc-select-arrow-wrapper {
        display: none;
      }
      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
      .compact-usages-percentage .mat-mdc-form-field-flex {
        padding: 0.75em 0.1em 0 0;
      }
    }
  }

  .rebuild-row-xs {
    .compact-elevator {
      min-width: 30px;
      /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
      .mat-select-trigger {
        display: inline-flex;
        align-items: stretch;
        cursor: pointer;
        position: relative;
        box-sizing: border-box;
        width: 100%;
        text-overflow: clip;
        min-width: 30px;
        right: 15%;
        /* justify-content: flex-start; */
        flex-wrap: nowrap;
      }
      /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
      .mat-mdc-form-field-flex .mat-mdc-select-arrow-wrapper {
        display: none;
      }

      //eventuell nur bei der % eingabe.
      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
      .compact-usages-percentage .mat-mdc-form-field-flex {
        padding: 0.75em 0.1em 0 0;
      }
    }
  }

  .mat-mdc-option .mat-pseudo-checkbox {
    display: none;
  }
}

.fixed-length-select .compactSelectInput .mat-mdc-form-field.cInputElementAdvanced,
.fixed-length-select .compactSelectInput .cInputElementAdvanced.mat-mdc-form-field {
  height: 100%;
  width: 160px;
  display: inline-block;
  float: left;
  margin-top: 1px;

  // Hide dropdown arrow when dropdown disabled
  .mat-form-field-disabled .mat-select-disabled .mat-select-arrow-wrapper {
    display: none;
  }
}
